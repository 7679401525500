const k = require('./keys');

const portuguese =  {
  [k.KAITECH]: `KaiTech Consulting - Seu Parceiro em Tecnologia`,
  [k.KAITECHCONSULTING]: `KaiTech Consulting`,
    [k.SERVICES]: `Serviços`,
  [k.SOLUTION]: `Marketing Digital - Solução completa para o seu sucesso.`,
  [k.IOT]: `IoT Sensores and Sistemas IoT com as mais avançadas tecnologias.`,
  [k.AUTOMOTIVE]: `Experiência em Conectar Veículos em um mundo conectado.`,
  [k.WEBDEV]: `Desenvolvimento de Sistemas Customizados para sua Aplicação.`,
  [k.SEMICONDUCTORS]: `Experiência em Módulos / Semiconductors para seus produtos.`,
  [k.UNIFIEDMANAGEMENT]: `Gerenciamento Inteligente Integrado de Recursos Hídricos.`,
  [k.KAIABOUTMSG1]: `A Kaitech Consulting foi fundada no final de 2009 com a
                    missão de fornecer excelência em serviços de consultoria
                    aos nossos clientes. Foco em trazer especialistas para nosso
                    grupo e impactar eficazmente nossos parceiros com tempo
                    de qualidade, soluções profissionais, acesso às tecnologias
                    de ponta criando um ambiente orientado para o sucesso..
                    Entre em contato conosco!`,
  [k.KAIABOUTMSG2]: `Marketing Digital,
                    Módulos Eletrônicos,
                    Gerenciamento de Recursos Hídricos,
                    Conectividade Automotiva,
                    Internet das Coisas - IoT,
                    Web development: Front and Back-end,
                    Homologações, certificações,
                    licenças de operação
                    e muito mais ....`,
  [k.KAIABOUTMSG3]: `Soluções inovadoras em Tecnologia e Serviços para atender
                    às necessidades de nossos clientes.`,
  [k.KAIABOUTMSG4]: `Diga-nos como podemos ajudá-lo.`,
  [k.KAITECHHOME]: `A Kaitech Consulting foi fundada no final de 2009 com a
                    missão de fornecer excelência em serviços de consultoria
                    aos nossos clientes. Foco em trazer especialistas para nosso
                    grupo e impactar eficazmente nossos parceiros com tempo
                    de qualidade, soluções profissionais, acesso às tecnologias
                    de ponta criando um ambiente orientado para o sucesso.`,
  [k.KAITECHHOME1]: `O principal objetivo da empresa é identificar e trazer para
                    a equipe, os melhores profissionais de tecnologia e fornecer
                    aos nossos clientes, serviços de consultoria de alta
                    qualidade nos segmentos tecnológicos mais interessantes do
                    mercado.`,
  [k.KAITECHHOME2]: `Soluções em Tecnologia e Serviços para atender
                    às necessidades de nossos clientes.`,
  [k.SIGNUPTOKAI]: `Registre-se na KaiTech`,
  [k.LOGINTOKAI]: `Entre em KaiTech`,
  [k.MKTDIGITAL]: `Marketing Digital`,
  [k.HOMOSERVICE]: `Homologações e Licenças`,
  [k.IOTSERVICE]: `Internet das Coisas`,
  [k.WIMSERVICE]: `Gerenciamento Águas`,
  [k.AUTOSERVICE]: `Soluções Conectividate Automotiva`,
  [k.WEBSERVICE]: `Desenvolvimento Web`,
  [k.SEMISERVICE]: `Módulos Eletrônicos`,
  [k.KAITECHSEMI]: `A Kaitech Consulting estabeleceu parcerias com importantes
                    fabricantes de módulos eletrônicos a fim de oferecer aos
                    nossos clientes a melhor solução para suas necessidades de
                    hardware com soluções inovadoras e otimizadas para
                    performance, consumo de energia ou necessidades com
                    propósitos específicos.`,
  [k.KAITECHSEMI1]: `Processadores, Dispositivos de Rastreamento, Data loggers,
                    Módulos Telemáticos, GPS, Módulos OBD-II , Módulos de
                    Memórias, Sensores MEM ,  Módulos de comunicações: 3G, 4G,
                    GPRS, Lora, Sigfox entre outros.`,
  [k.KAITECHSEMI2]: `Soluções em Módulos Eletrônicos para atender
                    as necessidades de nossos clientes.`,
  [k.KAITECHWEB]: `A Kaitech Consulting tem a expertise para oferecer soluções
                  Web customizadas para atender às necessidades de nossos
                  clientes. Sistemas, sites, Aplicativos para desktop e celular,
                   APIs, aprendizado de máquina, Inteligência Artificial e
                   muito mais.`,
  [k.KAITECHWEB1]: `Trabalhamos com ferramentas de alta performance, framewors,
                    linguagens de programação e Bancos de Dados como:
                    React, Laravel, Ruby on Rails, Javascript, Php, Python, C#,
                    C++, SQL, MongoDB, MySQL, PostgreSQL.`,
  [k.KAITECHWEB2]: `Soluções em Desenvolvimento Web para atender
                    às necessidades de nossos clientes.`,
  [k.KAITECHAUTO]: `A Kaitech Consulting tem a expertise em oferecer soluções de
                    conectividade automotiva para atender às necessidades de
                    nossos clientes. Dispositivos telemáticos, HeadUnits,
                    serviços baseados em nuvem, Apps, aprendizado de máquina e
                    inteligência artificial - diagnóstico inteligente para
                    veículos, integração de tecnologias, Soluções V2X,
                    integração de sistemas, CRM e DSM integrações e mais...`,
  [k.KAITECHAUTO1]: `BigData, Data Lake, Gateways, TCUs, RTOS para Telemática,
                    MQTT NS, MQTT Servers, Apps, Infotainment, integração de
                    aplicações, etc.`,
  [k.KAITECHAUTO2]: `Soluções em Connectividade de Veículos para atender
                    às necessidades de nossos clientes.`,
  [k.KAITECHWIM]: `A plataforma de Gestão Inteligente de Água - WIM da
                  Kaitech Consulting reúne modernas e poderosas
                  ferramentas para assegurar a sustentabilidade hídrica em áreas
                  diversas, desde indústrias, comércios, residências e
                  prestadoras de serviços, até o abastecimento de bairros,
                  cidades e o planejamento de fornecimento otimizado de
                  regiões.`,
  [k.KAITECHWIM1]: `A Plataforma de gestão Inteligente da Água - WIM está
                    baseada no recebimento de dados de hidrometros instalados
                    em indústrias, comércios e residências e na integração de
                    dados que subsidiam a gestão, permitindo que as decisões
                    sejam tomadas baseadas em dados obtidos em tempo real,com
                    mais segurança e maior alcance.`,
  [k.KAITECHWIM2]: `Soluções em Gerenciamento de Recursos Hídricos para atender
                    às necessidades de nossos clientes.`,
  [k.KAITECHIOT]: `A Kaitech Consulting se especializou em Sistemas Iot
                  incluindo: Desenvolvimento de COS - Centro de Operação de
                  Sistema, concentradores para áreas rurais e urbanas, sensores
                  IoT para curta, média e longa distância. Integração de
                  sistemas e tecnologias.`,
  [k.KAITECHIOT1]: `Soluções Cloud ou on premise, dispositivos baseados em:
                    M2M, IIoT, HIoT, CIoT, Tecnologias - 2G, 3G, 4G, LTE,
                    Lorawam, SigFox, 433/866 entre outros.
                    Soluções para Casa, Indústria 4.0, Pessoas, Veículos,
                    Agricultura, Deteção de Fenômenos, entre outros.`,
  [k.KAITECHIOT2]: `Soluções em Internet da Coisas para atender
                    às necessidades de nossos clientes.`,
  [k.KAITECHMKT]: `A Kaitech Consulting tem expertise em oferecer aos nossos
                  clientes, soluções de marketing personalizadas para todos
                  os segmentos deste importante setor. Soluções para todos os
                  tamanhos de empresas. Encontrar online clientes em potencial
                  atrai um grupo maior de pessoas do que você provavelmente
                  sería capaz de atrair localmente alcançando uma enorme
                  audiência.`,
  [k.KAITECHMKT1]: `Persona, SEO, Analytics, Criação de Leads, Branding, Sites,
                    Blogs, Landing Pages, Email Marketing, Mídias Sociais:
                    Facebook, Instagram, Youtube, Linkedin, Tweeter, Pinterest
                    entre outros.`,
  [k.KAITECHMKT2]: `Soluções em Marketing Digital para atender
                    às necessidades de nossos clientes.`,
  [k.MUCHMORE]: `e muito mais...`,
  [k.CONTACTUS]: `Entre em Contato`,
  [k.FIRSTNAME]: `Nome`,
  [k.LASTNAME]: `Sobrenome`,
  [k.TITLE]: `Cargo`,
  [k.COMPANY]: `Companhia`,
  [k.PHONE]: `Telefone`,
  [k.OPTIONS]: `Opções`,
  [k.NEWSLETTER]: `Newsletter`,
  [k.TEXTAREA]: `Mensagem do Cliente`,
  [k.ENTRECONTATO]: `Entre em Contato.`,
  [k.SUBSCRIBERS]: `Assinantes`,
  [k.OPTION]: `Opção`,
  [k.SOURCE]: `Fonte`,
  [k.NOTLOGGED]: `Favor selecionar 'Entrar' para acessar KAITECH Newsletter.`,
  [k.REMARKS]: `Observações`,
  [k.USERS]: `Usuários`,
  [k.USER]: `Usuário`,

  



  [k.WIM]: `WIM - Gerenciamento Inteligente da Água`,
  [k.HEADCONC]: ` - Medidas por Sensor por Concentrador`,
  [k.HEADCONCGRAPH]: `Concentradores: Medidas por Sensor`,
  [k.SEARCH]: `Busca`,
  [k.ABOUT_US]: `Quem somos`,
  [k.ABOUT]: `Sobre`,
  [k.ABOUTMSG1]: `A plataforma de Gestão Inteligente de Água - WIM da
                  Dataintellig Consulting reúne modernas e poderosas
                  ferramentas para assegurar a sustentabilidade hídrica em áreas
                  diversas, desde indústrias, comércios, residências e
                  prestadoras de serviços, até o abastecimento de bairros,
                  cidades e o planejamento de fornecimento otimizado de
                  regiões.`,
  [k.ABOUTMSG2]: `A Gestão Inteligente da Água - WIM está baseada no
                  recebimento de dados de hidrometros instalados em indústrias,
                  comércios e residências e na integração de dados que subsidiam
                   a gestão, permitindo que as decisões sejam tomadas baseadas
                   em dados obtidos em tempo real,com mais segurança e maior
                   alcance. Um centro de operações do sistema permite o manuseio
                   das informações, cálculo de vazão, geração de alarmes por
                   vazamento, fraudes, alterações de consumo, dados preditivos
                   de consumo por usuário, grupos, regiões e ou cidades
                   inteiras. Otimiza os recursos aplicados e minimiza custos
                   financeiros, sociais e ambientais.`,
  [k.ABOUTMSG3]: `Os recursos hídricos na indústria e no comércio tem papel
                  importante na nos custos e nos recursos de produção e no meio
                  ambiente. É matéria prima fundamental na maioria doss produtos
                  industrializados. Gerenciar este precioso recurso - água, é
                  a base para a otimização de todos os processos industriais.
                  Otimizar o uso dos recursos hídricos, reduzindo custos
                  sociais, ambientais e financeiros, é o grande desafio da
                  sociedade moderna.`,
  [k.LOGIN]: `Entrar`,
  [k.LOGINTOWIM]: `Entrar no WIM`,
  [k.MAP]: `Mapa`,
  [k.MAPS]: `Mapas`,
  [k.MAPGROUPS]: `Mapa de Grupos`,
  [k.MAPCONCENTRATORS]: `Mapa de Concentradores`,
  [k.MAPSENSORS]: `Mapa dos Sensores`,
  [k.DATA]: `Dados`,
  [k.SIGNUP]: `Registrar`,
  [k.SIGNUPTOWIM]: `Registro no WIM`,
  [k.SETTINGS]: `Configuração`,

  [k.USERCREATED]: `Usuário Criado!`,
  [k.CONCENTRATOR]: `Concentrador`,
  [k.SENSOR]: `Sensor`,
  [k.MEASURE]: `Medida`,
  [k.NEWGROUP]: `Novo Grupo`,
  [k.GROUP]: `Grupo`,
  [k.GROUPS]: `Grupos`,
  [k.GROUPDATA]: `Dados Grupo`,
  [k.GROUPCREATED]: `Grupo Criado!`,
  [k.GROUPID]: `Id do Grupo`,
  [k.DELETEGROUP]: `Apagar Grupo?`,
  [k.GROUPNAME]: `Nome do Grupo`,
  [k.NEWCONCENTRATOR]: `Novo Conc.`,
  [k.CONCENTRATORS]: `Concentradores`,
  [k.CONCENTRATORDATA]: `Dados Concentraror`,
  [k.CONCENTRATORCREATED]: `Concentrador Criado!`,
  [k.NEWSENSOR]: `Novo Sensor`,
  [k.SENSORS]: `Sensores`,
  [k.SENSORDATA]: `Dados Sensor`,
  [k.SENSORSBYCONC]: `Sensores por Concentrador`,
  [k.SENSORCREATED]: `Sensor Criado!`,
  [k.DELETECONCENTRATOR]: `Apagar Concentrador?`,
  [k.DELETESENSOR]: `Apagar Sensor ?`,
    [k.DELETEUTILITY]: `Apagar Concessionária ?`,
  [k.DELETEUSER]: `Apagar Usuário ?`,
  [k.DELETEMEASURE]: `Apagar Medida ?`,
  [k.NEWMEASURE]: `Nova Medida`,
  [k.MEASURES]: `Medidas`,
  [k.MEASURESBYSENSOR]: `Medidas por Sensor`,
  [k.ACTIVATEDSENSORS]: `Sensores Ativados`,
  [k.MEASURESBYSBYC]: `Medidas por Sensor por Concentrador`,
  [k.MEASURECREATED]: `Medida Criada!`,
  [k.LOGS]: `Logs`,
  [k.LOGOUT]: `Sair`,
  [k.INFRASTRUCTURE]: `Infra-estrutura`,
  [k.PREVIOUS]: `Anterior`,
  [k.NEXT]: `Próximo`,
  [k.USERNAME]: `Usuário`,
  [k.ROLE]: `Função`,
  [k.MESSAGE]: `Mensagem`,
  [k.CREATED]: `Criado`,
  [k.LASTLOGIN]: `Última entrada`,
  [k.ADMINISTRATOR]: `Administrador`,
  [k.DELETE]: `Apagar`,
  [k.UPDATE]: `Atualizar`,
  [k.CANCEL]: `Cancelar`,
  [k.PASSWORD]: `Senha`,
  [k.PASSWORDCONFIRMATION]: `Confirmar Senha`,
  [k.NOTAUTHORIZED]: `Não Autorizado`,
  [k.NOTAUTHORIZEDPLEASELOGIN]: `Não autorizado - Favor 'Entrar'!`,
  [k.NOTFOUND]: `Não encontrado`,
  [k.ERROR]: `Erro`,
  [k.MISSING]: `Não encontrado`,
  [k.WIMINNUMBERS]: `WIM em Números`,
  [k.BY]: `por`,
  [k.MEASUREDATE]: `Data da medida`,
  [k.CONSUMPTION]: `Consumo`,
  [k.HYDROMETER]: `Hidrômetro`,
  [k.LITERS]: `Litros`,
  [k.GALLONS]: `Galões`,
  [k.TIME]: `Tempo`,
  [k.VERSUS]: `vs.`,
  [k.LATITUDE]: `Latitude`,
  [k.LONGITUDE]: `Longitude`,
  [k.ADDRESS]: `Endereço`,
  [k.CITY]: `Cidade`,
  [k.STATE]: `Estado`,
  [k.COUNTRY]: `País`,
  [k.ZIPCODE]: `CEP`,
  [k.CONSTANT]: `Constante`,
  [k.ACTIVATION]: `Ativação`,
  [k.ADDMEASUREWIM]: `Adicionar Medida ao WIM`,
  [k.ADDSENSORWIM]: `Adicionar Sensor ao WIM`,
  [k.CREATENEWCONC]: `Criar Novo Concentrador?`,
  [k.CREATENEWGROUP]: `Criar Novo Grupo?`,
  [k.ADDCONCENTRATORWIM]: `Adicionar Concentrador ao WIM`,
  [k.ADDGROUPTOWIM]: `Adicionar Grupo ao WIM`,
  [k.ADDUTILITYTOWIM]: `Adicionar Concessionária ao WIM`,
  [k.SAVENEW]: `Salvar Novo`,
  [k.BACK]: `Retornar`,
  [k.OBS]: `Obs.`,
  [k.MEASUREEND]: `Fim da Medida`,
  [k.MEASURESTART]: `Inicio da Medida`,
  [k.SENSORTIME]: `Inicio Tempo Sensor`,
  [k.SENSORTIMEEND]: `Final Tempo Sensor`,
  [k.PASSWORDNOTMATCH]: `Confirmação de Senha diferente.`,
  [k.MSGSIGNUP]: `Criado pelo Registrar`,
  [k.SITENAME]: `Nome do Site`,
  [k.CONNECTION]: `Conexão`,
  [k.MAXHYDROMETERS]: `Máx. Hidrômetros`,
  [k.LANGUAGES]: `Idioma`,
  [k.ENGLISH]: `en`,
  [k.PORTUGUESE]: `pt`,
  [k.CHANGELANGUAGE]: `Mudança de idioma requer novo 'Entrar'! Prossegue?`,

  [k.CSO]:
    `Central de Operação do Sistema - Integração dos recursos de gestão.`,
  [k.PERPAGE]: `Por pag.:`,
  [k.INVALIDENTRY]: `Entrada Inválida!`,
  [k.INDUSTRIES]: `Indústrias`,
  [k.INVESTORRELATIONS]: `Relações Investidores`,

  [k.PRIVACYPOLICY]: `Política de Privacidade`,
  [k.COOKIESPOLICY]: `Política de 'Cookies'`,
  [k.TERMSOFSERVICE]: `Termos de Serviço`,
  [k.NAME]: `Name`,
  [k.COLOR]: `Color`,
  [k.ID]: `Id`,
  [k.CREATION]: `Creation`,
  [k.USERID]: `Id Usuário`,
  [k.OWNER]: `Responsável`,
  [k.NOMEASURES]: `Sensor não possui Medidas!`,
  [k.GENERAL]: `Geral`,
  [k.STATUS]: `Status`,
  [k.UNPROCESSABLE]: `Não Processável`,
  [k.GENERALALARM]: `Alarme Geral`,
  [k.LEAKAGE]: `Alarme Vazamento`,
  [k.FRAUD]: `Alarme Fraude`,
  [k.BLOCKED]: `Bloqueado`,
  [k.CONNECTED]: `Conectado`,
  [k.NORMALFLOW]: `Fluxo Regular`,
  [k.REVERSE]: `Fluxo Reverso`,
  [k.UNDERFLOW]: `Fluxo Baixo`,
  [k.OVERFLOW]: `Fluxo Alto`,
  [k.STOPPED]: `Medidor Parado`,
  [k.LASTCOMM]: `Última Com.`,
  [k.ITEMS]: `Item(s)`,
  [k.RANGESELECTION]: `Seleção de Datas`,
    [k.CONSSELECTION]: `Seleção por Consumo`,
  [k.STARTDATE]: `Data inicial`,
  [k.ENDDATE]: `Data Final`,
  [k.SELECTONE]: `Selecione Grupo`,
  [k.LEGENDS]: `Legendas`,
  [k.DATUM]: `Dados`,
  [k.NOCONNECTION]: `Sem Conexão!`,
  [k.FILTER]: `Filtrar por Datas`,
  [k.FILTERCONS]: `Filtrar por Consumo`,
  [k.REPORTS]: `Relatórios`,
  [k.UTILITY]: `Concessionária`,
  [k.UTILITIES]: `Concessionárias`,
  [k.COD]: `Código`,
  [k.ACTUALMEASDATE]: `Data Medição Atual`,
  [k.PREVIOUSMEASDATE]: `Data Medição Prévia`,
  [k.MEASDAY]: `Dia da Medição`,
  [k.ACTIVE]: `Ativo`,
  [k.UTILITYNAME]: `Nome da Concessionária`,
  [k.TRUE]: `Verdadeiro`,
  [k.FALSE]: `Falso`,
  [k.NEWUTILITY]: `Nova Concessionária`,
  [k.UTILITYCREATED]: `Concessionária Criada`,
  [k.DISCONNECTED]: `Disconectado`,
  [k.WARNINGS]: `Alertas`,
  [k.FRAUDDETECTED]: `Fraude Detectada`,
  [k.DAYS]: `Dia(s)`,
  [k.LAST]: `Última`,
  [k.CLEAR]: `Limpar`,
  [k.ERRORCREATIONUSER]: `Erro no registro de usuário!`,
  [k.SUCCESSCREATIONUSER]: `Usuário criado com sucesso!`,
  [k.ERRORLOGINUSER]: `Erro no login usuário!`,
  [k.SUCCESSLOGINUSER]: `Login usuário com sucesso!`,
};
export { portuguese };
