import React, { Component } from 'react';

import i18n from 'i18next';
import k from "./../i18n/keys";

import home from '../assets/images/webdevelopment3.jpg';
import './../assets/wim.scss';
import './../assets/Navbar.scss';
import './../assets/d3.scss';

import { UserContext } from '../App';

import * as d3 from "d3";

import Footer from './Footer';


//import img9 from "../assets/images/iot3.jpg";
//

class Dash extends Component {

  constructor(props) {
    super(props)

    this.state = {
      interval: '',
      username: UserContext._currentValue.user,
      value: 0,
    }

  }


  async componentDidMount()  {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const data = await d3.json('data.json')
            .then( data => {
              return data
            });

    const width = 800;
    const height = 400;
    const margin = { top: 20, right: 20, bottom: 30, left: 30};
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const gap = 5;
    const svg_title = 'SVG Title - Bar Graph'



    const render = data => {

      // const xValue = d => d.width;
      // const yValue = d => d.value;

      const yScale = d3.scaleLinear()
              .domain([0, d3.max(data, d => d.value )])
              .range([0, 250])

      const xScale = d3.scaleBand()
            .domain([data.map(item => item.title)])
            .range([0, innerWidth])
            .round(true)

      const test = [data.map(item => item.title)];
      console.log(test, xScale.bandwidth() / data.length);

      const canvas = d3.select('.canvas');

      const div = d3.select('body').append('div')
            .attr('class', 'div-tooltip')
            .style('opacity', 0);

      const svg = canvas.append('svg')
                      .attr('xmlns', 'http://www.w3.org/2000/svg')
                      .attr('viewBox', `0 0 ${width} ${height}`)
                      .attr('width', width)
                      .attr('height', height)
                      .attr('class', 'svg-view-box');

      svg.append('text')
            .attr('class', 'svg-title')
            .attr('x', innerWidth / 2 - 50)
            .attr('y', margin.top)
            .attr('stroke', 'steelblue')
            .attr('fill', 'steelblue')
            .text(svg_title)


      const g = svg
            .append('g')
              .attr('transform', `translate(${margin.left},${margin.top})`);

      const rect = g.selectAll('.rect')
                      .data(data)

      rect.enter()
          .append('rect')
          .attr('x', (d, i) => (i * xScale.bandwidth() / data.length))
          .attr('y', d => innerHeight - yScale(d.value) )
          .attr('width', xScale.bandwidth() / data.length - gap) //d => d.width - gap)
          .attr('height', d => yScale(d.value))
          .style('fill', d => d.fill)
          .on('mouseover', (event, d) => {
            d3.select(this)
              .transition()
              .duration(100)
              .style('opacity', 0.3)

              div.transition()
              .duration(200)
              .style('opacity', 0.9)

              div.html(`<p class="tooltip-text">
                ${'Title'}: ${d.title}
                ${'Value'}: ${d.value}

                </p>`)
              .style('left', (event.pageX) + 'px' )
              .style('top', ( -innerHeight * 1.5 ) + 'px' )
          })

      rect.enter()
      .append('text')
      .attr('x', (d, i) => (i * xScale.bandwidth() / data.length))
      .attr('y', d => innerHeight - yScale(d.value) - gap )
      .attr('stroke', 'steelblue')
      .attr('fill', 'steelblue')
      .text((d) => d.title)
    }
    render(data);
  }


  render() {

    return(
      <React.Fragment>
      <div className="container" id="top">
        <h1 className="center page-header" data-test="dash-header">
        {i18n.t(k.KAITECH)} - {i18n.t(k.ABOUT)}</h1>
        <hr />
        <img data-testid="html-element" className="img-center img-fluid"
        src= {home} alt="Energy Water" />
        <h3 className="center page-header">  {i18n.t(k.KAITECH)} </h3>

      </div>
      <div className="canvas">

      </div>

      <Footer />
      </React.Fragment>


    )
  }
}

export default Dash;
