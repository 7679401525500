import React, { Component } from 'react';

import i18n from 'i18next';
import k from "./../i18n/keys";

import ReactGA from 'react-ga';

import home from '../assets/images/webdevelopment3.jpg';
import './../assets/wim.scss';
import './../assets/Navbar.scss';

import { UserContext } from '../App';


import Footer from './Footer';


//import img9 from "../assets/images/iot3.jpg";
//

class About extends Component {

  constructor(props) {
    super(props)

    this.state = {
      interval: '',
      username: UserContext._currentValue.user,
      value: 0,
    }

  }


  async componentDidMount()  {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

  }

  componentDidUpdate(prevProps, prevStates) {

  };


  render() {

    ReactGA.event({
        category: "About",
        action: `Access to About from
          ${this.state.username}`,
        labels: 'Kaitech About'
    });

    return(
      <React.Fragment>
      <div className="container" id="top">
        <h1
          className="center page-header"
          data-test="page-header"
          >
        {this.props.name} - {i18n.t(k.ABOUT)}</h1>
        <hr />
        <img data-testid="html-element" className="img-center img-fluid"
        src= {home} alt="Energy Water" data-test="about-image"/>
        <h3 className="center page-header">  {i18n.t(k.KAITECH)} </h3>
        <div className="page-text" data-test="about-body">
          <p className="page-text"
            data-test="about-body">{i18n.t(k.KAIABOUTMSG1)}</p>
          <hr className="page-text"/>
          <p className="page-banner "
            data-test="about-body">&#10004;  {i18n.t(k.MKTDIGITAL)},</p>
          <p className="page-banner "
            data-test="about-body">&#10004;  {i18n.t(k.SEMISERVICE)},</p>
          <p className="page-banner"
            data-test="about-body">&#10004;  {i18n.t(k.WIMSERVICE)},</p>
          <p className="page-banner"
            data-test="about-body">&#10004;  {i18n.t(k.AUTOSERVICE)},</p>
          <p className="page-banner"
            data-test="about-body">&#10004;  {i18n.t(k.IOTSERVICE)},</p>
          <p className="page-banner"
            data-test="about-body">&#10004;  {i18n.t(k.WEBSERVICE)}
            - Front and Back-end,</p>
          <p className="page-banner"
            data-test="about-body">&#10004;  {i18n.t(k.HOMOSERVICE)},</p>
          <p className="page-banner"
            data-test="about-body">&#10004;  {i18n.t(k.MUCHMORE)}</p>
          <p className="page-text"
            data-test="about-body">{i18n.t(k.KAIABOUTMSG3)}</p>
          <br />
          <br />
          <br />
          <hr />
        </div>
      </div>

      <Footer />
      </React.Fragment>


    )
  }
}


export default About;
