const k = require('./keys');

const english = {
  [k.KAITECH]: `KaiTech Consulting - Your Technology Partner`,
  [k.KAITECHCONSULTING]: `KaiTech Consulting`,
  [k.SERVICES]: `Services`,
  [k.SOLUTION]: `Complete Digital Marketing Solution for Your Success.`,
  [k.IOT]: `IoT Sensors and Sistems in the most advanced Technologies.`,
  [k.AUTOMOTIVE]: `Expertise in Connecting Vehicles for a Connected World.`,
  [k.WEBDEV]: `Systems Development for a large number of Applications`,
  [k.SEMICONDUCTORS]: `Expertise in Semiconductors Modules for your products.`,
  [k.UNIFIEDMANAGEMENT]: `Integrated Intelligent Water Resources Management.`,
  [k.KAIABOUTMSG1]: `Kaitech Consulting was founded in 2009.The main goal of the
  company is to provide the best consulting services to our customers with
  the best team of technology professionals in the most exciting technology
  segments of the market.`,
  [k.KAIABOUTMSG2]: `Electronic Modules,
                    Digital Marketing,
                    Water management,
                    Automotive Connectivity,
                    Internet of Things - IoT,
                    Web development: Front and Back-end,
                    Homologations, certifications,
                    operation licences
                    and much more ....`,
  [k.KAIABOUTMSG3]: `Kaitech Consulting's Intelligent Water Management - WIM
                  platform brings together modern and powerful tools to ensure
                  water sustainability in different areas, from industries,
                  businesses, residences and service providers, even supplying
                  neighborhoods, cities and planning for optimal supply of
                  regions.`,
  [k.KAIABOUTMSG4]: `Tell us how we can help you!`,
  [k.KAITECHHOME]: `Kaitech Consulting was founded at the end of 2009 with a
                    mission of providing outstanding consulting services to our
                    customers. Focus on bringing experts to our group and
                    effectively impact our partners with quality time,
                    professional solutions, access to top technologies creating
                    an environment success oriented.`,
  [k.KAITECHHOME1]: `The main goal of the company is to identify and bring to the
                    team, the best technology professionals and provide
                    to our customers, top quality consulting services in the
                    most exciting technological segments of the market.`,
  [k.KAITECHHOME2]: `Solutions in Technology and Services to fulfill our
                    customers needs.`,

  [k.SIGNUPTOKAI]: `Signup to KaiTech`,
  [k.LOGINTOKAI]: `Login to KaiTech`,

  [k.WIM]: `WIM - Water Intelligent Management`,
  [k.HEADCONC]: ` - Measures By Sensor By Concentrator`,
  [k.HEADCONCGRAPH]: `Concentartors: Measures By Sensor`,
  [k.SEARCH]: `Search`,
  [k.ABOUT_US]: `About us`,
  [k.ABOUT]: `About`,
  [k.MKTDIGITAL]: `Digital Marketing`,
  [k.HOMOSERVICE]: `Homologations and Licenses`,
  [k.IOTSERVICE]: `Internet of Things`,
  [k.WIMSERVICE]: `Water Management`,
  [k.AUTOSERVICE]: `Automotive Connectivity Solutions`,
  [k.WEBSERVICE]: `Web Development`,
  [k.SEMISERVICE]: `Electronic Modules`,
  [k.KAITECHSEMI]: `Kaitech Consulting has established partnerships with key
                    electronic modules manufacturers in order to offer to our
                    customers the best solution for their hardware needs with
                    innovative and optimized solutions for performance,
                    energy consumption or specific purposes.`,
  [k.KAITECHSEMI1]: `Processors, Tracking devices, Data loggers, Telematics
                    modules, GPS, OBD-II Modules, Memory Modules, MEM Sensors,
                    communication modules: 3G, 4G, GPRS, Lora, Sigfox among
                     others.`,
  [k.KAITECHSEMI2]: `Solutions in Electronic modules to fulfill our customers
                    needs.`,
  [k.KAITECHWEB]: `Kaitech Consulting has the expertise to offer customized Web
                  solutions to fullfill our customer needs. Systems, Sites,
                  Applications for Desktop and Mobile, APIs, Machine Learning,
                  Artificial Inteligence and much more.`,
  [k.KAITECHWEB1]: `Working with top performance framewors, languages and
                    databases like:
                    React, Laravel, Ruby on Rails, Javascript, Php, Python, C#,
                    C++, SQL, MongoDB, MySQL, PostgreSQL.`,
  [k.KAITECHWEB2]: `Solutions in Web Development to fulfill our customers
                    needs.`,
  [k.KAITECHAUTO]: `Kaitech Consulting has the expetize to offer customized
                    Automotive Connectivity solutions to fullfill our customer
                    needs. Telematics Devices, HeadUnits, Cloud Based Services,
                    Apps, Machine Learning and Artificial Inteligence for
                    Vehicle Intelligent Diagnosys, Integration of Technologies,
                    V2X Solutions, Eco System integration, CRM and DSM
                    integrations and more...`,
  [k.KAITECHAUTO1]: `BigData, Data Lake, Gateways, Telematics Control Units -
                    TCUs, RTOS for Telematics devices, MQTT NS Devices,
                    MQTT Service Servers, Apps, Head Unit Application Integration, etc.`,
  [k.KAITECHAUTO2]: `Solutions in Automotive Connectivity to fulfill our
                    customers needs.`,
  [k.KAITECHWIM]: `Kaitech Consulting's Intelligent Water Management - WIM
                  platform brings together modern and powerful tools to ensure
                  water sustainability in different areas, from industries,
                  businesses, residences and service providers, even supplying
                  neighborhoods, cities and planning for optimal supply of
                  regions.`,
  [k.KAITECHWIM1]: `Intelligent Water Management - WIM is based on receiving
                  hydrometer data installed in industries, businesses and homes
                  and in the integration of data that support management,
                  allowing decisions to be taken based on data obtained in real
                  time, more securely with broad reach of information.`,
  [k.KAITECHWIM2]: `Solutions in Water Management to fulfill our customers
                  needs.`,
  [k.KAITECHIOT]: `Kaitech Consulting has expertise in Iot Systems for several
                  applications including: Development of CSO - Center of
                  System Operation applications, Concentrators for Rural and
                  Urban areas, IoT sensors for short, medium and long range
                  applications. Integration of systems and technologies.`,

  [k.KAITECHIOT1]: `Cloud or on premise Systems and devices based on:
                    M2M, IIoT, HIoT, CIoT, Technologies - 2G, 3G, 4G, LTE,
                    Lorawam, SigFox, 433/866 frequencies among others.
                    Solutions for Home, Industry 4.0, Persons, Vehicles,
                    Agriculture, Phenomena Detection, among others.`,
  [k.KAITECHIOT2]: `Solutions in IoT to fulfill our customers
                    needs.`,
  [k.KAITECHMKT]: `Kaitech Consulting has the expertise in offering to our
                  customers, customized Marketing solutions for all important
                  segments of this important sector. Solutions for all sizes
                  companies.Finding potential customers online brings a much
                  larger group of people than you are likely to be able to
                  attract locally. Using digital marketing, you can reach an
                  enormous audience.`,
  [k.KAITECHMKT1]: `Persona definition, SEO, Analytics, Leads creation,
                    Branding, Sites, Blogs, Landing Pages, Email Marketing,
                    Social Medias: Facebook, Instagram, Youtube, Linkedin,
                    Tweeter, Pinterest among others.`,
  [k.KAITECHMKT2]: `Solutions in Digital Marketing to fulfill our customers
                    needs.`,
  [k.MUCHMORE]: `and much more...`,
  [k.CONTACTUS]: `Contact Us`,
  [k.FIRSTNAME]: `Firstname`,
  [k.LASTNAME]: `Lastname`,
  [k.PHONE]: `Phone Number`,
  [k.OPTIONS]: `Options`,
  [k.NEWSLETTER]: `Newsletter`,
  [k.TEXTAREA]: `Customer Message`,
  [k.TITLE]: `Title`,
  [k.COMPANY]: `Company`,
  [k.ENTRECONTATO]: `Contact us.`,
  [k.SUBSCRIBERS]: `Subscribers`,
  [k.OPTION]: `Option`,
  [k.SOURCE]: `Source`,
  [k.NOTLOGGED]: `You are not logged in! Login to use KAITECH Newsletter.`,
  [k.REMARKS]: `Remarks`,
  [k.USERS]: `Users`,
  [k.USER]: `User`,






  [k.ABOUTMSG2]: `Intelligent Water Management - WIM is based on receiving
                  hydrometer data installed in industries, businesses and homes
                  and in the integration of data that support management,
                  allowing decisions be taken based on data obtained in real
                  time, more securely and broad reach of information. A system
                  operations center allows handling information, flow
                  calculation, generation of leakage alarms, fraud, consumption
                  changes, predictive consumption data per user, groups, regions
                   and or entire cities. Optimizes the resources applied and
                   minimizes financial, social and environmental costs.`,
  [k.ABOUTMSG3]: `Water resources in the industry and commerce play an important
                  role in production costs, financial resources and in the
                  environment. It is a fundamental raw material in most
                  industrialized products. Managing this precious resource -
                  water, is the basis for optimizing all industrial processes.
                  Optimize the use of water resources, reducing social costs,
                  environmental and financial, is the great challenge of modern
                  society.`,
  [k.LOGIN]: `Login`,
  [k.LOGINTOWIM]: `Login to WIM`,
  [k.MAP]: `Map`,
  [k.MAPS]: `Maps`,
  [k.MAPGROUPS]: `Groups' Map`,
  [k.MAPCONCENTRATORS]: `Concentartors' Map`,
  [k.MAPSENSORS]: `Sensors' Map`,
  [k.DATA]: `Data`,
  [k.SIGNUP]: `Sign-up`,
  [k.SIGNUPTOWIM]: `Signup to WIM`,
  [k.SETTINGS]: `Settings`,

  [k.USERCREATED]: `User Created!`,
  [k.CONCENTRATOR]: `Concentrator`,
  [k.SENSOR]: `Sensor`,
  [k.MEASURE]: `Measure`,
  [k.NEWGROUP]: `New Group`,
  [k.GROUP]: `Group`,
  [k.GROUPS]: `Groups`,
  [k.GROUPDATA]: `Group Data`,
  [k.GROUPCREATED]: `Group Created!!`,
  [k.GROUPID]: `Group Id`,
  [k.DELETEGROUP]: `Delete Group?`,
  [k.GROUPNAME]: `Group Name`,
  [k.NEWCONCENTRATOR]: `New Conc.`,
  [k.CONCENTRATORS]: `Concentrators`,
  [k.CONCENTRATORDATA]: `Concentrator Data`,
  [k.CONCENTRATORCREATED]: `Concentrator Created!`,
  [k.NEWSENSOR]: `New Sensor`,
  [k.SENSORS]: `Sensors`,
  [k.SENSORDATA]: `Sensor Data`,
  [k.SENSORSBYCONC]: `Sensors By Concentrator`,
  [k.SENSORCREATED]: `Sensor Created!`,
  [k.DELETECONCENTRATOR]: `Delete Concentrator?`,
  [k.DELETESENSOR]: `Delete Sensor ?`,
  [k.DELETEUTILITY]: `Delete Utility ?`,
  [k.DELETEUSER]: `Delete User ?`,
  [k.DELETEMEASURE]: `Delete Measure ?`,
  [k.NEWMEASURE]: `New Measure`,
  [k.MEASURES]: `Measures`,
  [k.MEASURESBYSENSOR]: `Measures By Sensor`,
  [k.MEASURECREATED]: `Measure created!`,
  [k.ACTIVATEDSENSORS]: `Activated Sensors`,
  [k.MEASURESBYSBYC]: `Measures By Sensor By Concentrator`,
  [k.LOGS]: `Logs`,
  [k.LOGOUT]: `Logout`,
  [k.NEWMEASURES]: `Measures`,
  [k.INFRASTRUCTURE]: `Infrastructure`,
  [k.PREVIOUS]: `Previous`,
  [k.NEXT]: `Next`,

  [k.USERNAME]: `Username`,
  [k.ROLE]: `Role`,
  [k.MESSAGE]: `Message`,
  [k.CREATED]: `Created`,
  [k.LASTLOGIN]: `Last Login`,
  [k.ADMINISTRATOR]: `Administrator`,
  [k.DELETE]: `Delete`,
  [k.UPDATE]: `Update`,
  [k.CANCEL]: `Cancel`,
  [k.PASSWORD]: `Password`,
  [k.PASSWORDCONFIRMATION]: `Password Confirmation`,
  [k.NOTAUTHORIZED]: `Not Authorized`,
  [k.NOTAUTHORIZEDPLEASELOGIN]: `Not Authorized - Please Login!`,
  [k.NOTFOUND]: `Not Found`,
  [k.ERROR]: `Error`,
  [k.MISSING]: `Missing`,
  [k.WIMINNUMBERS]: `WIM in Numbers`,
  [k.BY]: `By`,
  [k.MEASUREDATE]: `Measure Date`,
  [k.CONSUMPTION]: `Consumption`,
  [k.HYDROMETER]: `Hydrometer`,
  [k.LITERS]: `Liters`,
  [k.GALLONS]: `Gallons`,
  [k.TIME]: `Time`,
  [k.VERSUS]: `vs.`,
  [k.LATITUDE]: `Latitude`,
  [k.LONGITUDE]: `Longitude`,
  [k.ADDRESS]: `Address`,
  [k.CITY]: `City`,
  [k.STATE]: `State`,
  [k.COUNTRY]: `Country`,
  [k.ZIPCODE]: `Zipcode`,
  [k.COMPANY]: `Company`,
  [k.CONSTANT]: `Constant`,
  [k.ACTIVATION]: `Activation`,
  [k.ADDMEASUREWIM]: `Add Measure to WIM`,
  [k.ADDSENSORWIM]: `Add Sensor to WIM`,
  [k.CREATENEWCONC]: `Create New Concentrator?`,
  [k.CREATENEWGROUP]: `Create New Group?`,
  [k.ADDCONCENTRATORWIM]: `Add Concentrator to WIM`,
  [k.ADDGROUPTOWIM]: `Add Group to WIM`,
  [k.ADDUTILITYTOWIM]: `Add Utility to WIM`,
  [k.SAVENEW]: `Save New`,
  [k.BACK]: `Back`,
  [k.OBS]: `Obs.`,
  [k.MEASUREEND]: `Measure End`,
  [k.MEASURESTART]: `Measure Start`,
  [k.SENSORTIME]: `Sensor Time`,
  [k.SENSORTIMEEND]: `Sensor Time End`,
  [k.PASSWORDNOTMATCH]: `Password does not match with confirmation.`,
  [k.MSGSIGNUP]: `Created by signup`,
  [k.SITENAME]: `Site Name`,
  [k.CONNECTION]: `Connection`,
  [k.MAXHYDROMETERS]: `Max. Hydrometers`,
  [k.LANGUAGES]: `Language`,
  [k.ENGLISH]: `en`,
  [k.PORTUGUESE]: `pt`,
  [k.CHANGELANGUAGE]: `Change language requires a logout! Proceed?`,

  [k.CSO]: `Central System Operation - Integration of Resources Management.`,

  [k.PERPAGE]: `Per page:`,
  [k.INVALIDENTRY]: `Invalid Entry!`,
  [k.INDUSTRIES]: `Industries`,
  [k.INVESTORRELATIONS]: `Investor Relations`,

  [k.PRIVACYPOLICY]: `Privacy Policy`,
  [k.COOKIESPOLICY]: `Cookies Policy`,
  [k.TERMSOFSERVICE]: `Terms of Service`,
  [k.NAME]: `Name`,
  [k.COLOR]: `Color`,
  [k.ID]: `Id`,
  [k.CREATION]: `Creation`,
  [k.USERID]: `User Id`,
  [k.OWNER]: `Owner`,
  [k.NOMEASURES]: `No Measures for this Sensor!`,
  [k.GENERAL]: `General`,
  [k.STATUS]: `Status`,
  [k.UNPROCESSABLE]: `Unprocessable Entity`,
  [k.GENERALALARM]: `General Alarm`,
  [k.LEAKAGE]: `Leakage Alarm`,
  [k.FRAUD]: `Fraud Alarm`,
  [k.BLOCKED]: `Blocked`,
  [k.CONNECTED]: `Connected`,
  [k.NORMALFLOW]: `Regular Flow`,
  [k.REVERSE]: `Reverse Flow`,
  [k.UNDERFLOW]: `Underflow`,
  [k.OVERFLOW]: `Overflow`,
  [k.STOPPED]: `Meter Stopped`,
  [k.LASTCOMM]: `Last Comm`,
  [k.TITLE]: `Title`,
  [k.ITEMS]: `Item(s)`,
  [k.RANGESELECTION]: `Dates Selection`,
  [k.CONSSELECTION]: `Consumption Selection`,
  [k.STARTDATE]: `Start Date`,
  [k.ENDDATE]: `End Date`,
  [k.SELECTONE]: `Select One`,
  [k.LEGENDS]: `Legends`,
  [k.DATUM]: `Data`,
  [k.NOCONNECTION]: `No Connection!`,
  [k.FILTER]: `Filter By Dates`,
  [k.FILTERCONS]: `Filter By Consumption`,
  [k.REPORTS]: `Reports`,
  [k.UTILITY]: `Utility`,
  [k.UTILITIES]: `Utilities`,
  [k.COD]: `Code`,
  [k.ACTUALMEASDATE]: `Actual Measurement Date`,
  [k.PREVIOUSMEASDATE]: `Previous Measurement Date`,
  [k.MEASDAY]: `Measurement Day`,
  [k.ACTIVE]: `Active`,
  [k.UTILITYNAME]: `Utility Name`,
  [k.TRUE]: `True`,
  [k.FALSE]: `False`,
  [k.NEWUTILITY]: `New Utility`,
  [k.UTILITYCREATED]: `Utility Created`,
  [k.DISCONNECTED]: `Disconnected`,
  [k.WARNINGS]: `Warnings`,
  [k.FRAUDDETECTED]: `Fraud Detected`,
  [k.DAYS]: `Day(s)`,
  [k.LAST]: `Last`,
  [k.CLEAR]: `Clear`,
  [k.ERRORCREATIONUSER]: `Error creating a user!`,
  [k.SUCCESSCREATIONUSER]: `User successfully created!`,
  [k.ERRORLOGINUSER]: `Error logging a user!`,
  [k.SUCCESSLOGINUSER]: `User successfully logged!`,


}
export { english };
