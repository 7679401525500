import { useState, useEffect } from "react";
import axios from 'axios'

export default url => {
  const baseUrl = 'https://kaitech.herokuapp.com';
  const [isLoading, setIsLoading] = useState(false);
  const [ response, setResponse] = useState(null);
  const [ error, setError] = useState(null);
  const [ options, setOptions ] = useState({});

  const doFetch = (options = {}) => {
    setOptions(options);
    setIsLoading(true);
  };

  useEffect( () => {
    async function fetchData() {
      if (isLoading === true){
        console.log('Fetch executed', options);
          await axios.post(baseUrl + url , options)
          .then(result => {
            setResponse(result.data);
            sessionStorage.setItem('token', result.data.jwt)
            setIsLoading(false);
            console.log('doFetch - ', result);
          }
          ).catch(err => {
            console.log('doFetch err- ', err.request.status);
            setError(err.request.status);
            setIsLoading(false);
          })
      }
      console.log('UseEffect triggered');
    }
    fetchData();

  },[isLoading, url, options]);

    return [{isLoading, response, error}, doFetch]
}
