import React from 'react';
import i18n from 'i18next';
import k from "./../i18n/keys";
import { useForm } from "./useForm";
import useFetch from "./useFetch";
import home from '../assets/images/webdevelopment3.jpg';
import './../assets/wim.scss';
import './../assets/Navbar.scss';

import Footer from './Footer';


const Hooks = (props) => {
  const isLogin = props.match.path === '/blog';
  console.log(isLogin);
  const [values, handleChange, handleCheckbox] = useForm({email: '', password: '', username: '', newsletter: false});
  const [{isLoading, error, response}, doFetch] = useFetch('/token');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.email && values.username && values.password) {
      doFetch(
        {
          auth: {
            username: values.username,
            email: values.email,
            password: values.password
          },
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Bearer': sessionStorage.getItem('token')
          }
        }
      )
    }
  }

    return(
      <React.Fragment>

      <div className="container" id="top">
        <h1 className="center page-header">
        {i18n.t(k.KAITECH)} - {i18n.t(k.ABOUT)}</h1>

        <hr />
        <img data-testid="html-element" className="img-center img-fluid"
        src= {home} alt="Energy Water" />
        <h3 className="center page-header">  {i18n.t(k.KAITECH)} </h3>
        <div className="page-text">
          <form className="form-group" onSubmit={handleSubmit}>
            <input
              className="form-control"
              type="email"
              placeholder="email"
              name="email"

              value={values.email}
              onChange={handleChange}
              />
            <input
              className="form-control"
              type="text"
              placeholder="username"
              name="username"

              value={values.username}
              onChange={handleChange}
              />
            <input
              className="form-control"
              type="password"
              placeholder="password"
              name="password"

              value={values.password}
              onChange={handleChange}
              autoComplete='true'
              />
              <input
                className="form-control"
                type="checkbox"
                placeholder="Newsletter"
                name="newsletter"
                value={values.newsletter}
                onChange={handleCheckbox}
                autoComplete='true'
                />
            <br />
            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-info btn-center">
              Click me
            </button>
          </form>
          <div className="hook-grid">
            <div className="item1">
              {`Email: ${values.email}`}
            </div>
            <div className="item2">
              {`Username: ${values.username}`}
            </div>
            <div className="item4">
              {`Password: ${values.password}`}
            </div>
            <div className="item5">
              {`Token: ${response === null ? '' : error !== null ? error : response.jwt}`}
            </div>
            <div className="item6">
              {`Validation: ${values.newsletter ? 'True' : 'False'}`}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      </React.Fragment>
    )
}

export default Hooks;
