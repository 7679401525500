import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'


import i18n from 'i18next';
import k from "./../i18n/keys";

import ReactGA from 'react-ga';

import './../assets/kaitech.scss';
import axios from 'axios';
import Billboard from './Billboard'

import Footer from './Footer';

class LoginPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      username: '',
      email: '',
      password: '',
      token: '',
      logged: false,
      lang: '',

      ok: false,
      fault: false,
      errors: [],
      isLoading: false,
      loading: false,
      error_show: 'none',
      opacity: 0,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  validate_form = () => {
    this.setState({errors: []});
    let array = [];
    if (this.state.username === ''){
      array.push(` ${i18n.t(k.USERNAME)}.\r\n`);
    };
    if (this.state.email === ''){
      array.push(" Email.\r\n");
    };
    if (this.state.password === ''){
      array.push(` ${i18n.t(k.PASSWORD)}.\r\n`);
    };

    if (array.length === 0) {
        return true
    }
    this.setState({errors: array});
    this.setState({error_show: 'block'});
    return false;
  }

  clearForm = (e) => {
    this.setState({errors: []});
    this.setState({error_show: 'none'});
  }

  onChange(e) {
    e.preventDefault();
    this.setState({[e.target.name]: e.target.value });
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  	this.setState({opacity: 0})
    document.getElementById('username').focus();
	}

  async onSubmit(e) {
    e.preventDefault();

    var config_token = {
        headers: {
          'Content-Type': 'application/json'
        }
    };

    var bodyParameters_token = {
      auth: {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
      }
    };

    if (this.validate_form()) {
      this.setState({opacity: 1});
      //sessionStorage.setItem('token', '');
      const url = 'https://kaitech.herokuapp.com/token';
      await axios.post(url , bodyParameters_token, config_token)
        .then(res => {
          this.setState({token: res.data.jwt})
        })
        .catch((error) => {
          this.setState({errors: []});
          let array = [];
          if (error.request.status === 404){
            array.push(`${i18n.t(k.ERROR)}: ${error.request.status} -
            ${i18n.t(k.NOTFOUND)}`);
          }
          if (error.request.status > 404){
            array.push(`${i18n.t(k.ERROR)}: ${error.request.status} -
            ${i18n.t(k.NOCONNECTION)}`);
          }
          this.setState({errors: array});
          this.setState({error_show: 'block'});
          this.setState({opacity: 0});
      });



      var config_current = {
          headers: {
            'Authorization': "Bearer " + this.state.token,
            'Content-Type': 'application/json'
                  }
      };

      if (this.state.token !== ''){
        const url = 'https://kaitech.herokuapp.com/user/current';
        await axios.get(url, config_current)
        .then(response => {
          this.setState({ok: true});
          this.setState({opacity: 0});
          this.setState({username: response.data.username})
          this.setState({logged: true})
          this.setState({role: response.data.role})
          this.setState({lang: response.data.aux2})
          this.props.current_user(response.data.username);
          this.props.token(this.state.token);
          //this.props.history.push('/home');
        })
        .catch((error) => {
          this.setState({errors: []});
          let array = [];
          if (error.request.status === 401){
            array.push(`${i18n.t(k.ERROR)}: ${error.request.status} -
            ${i18n.t(k.NOTAUTHORIZED)}`);
          }
          this.setState({fault: true});
          this.setState({errors: array});
          this.setState({error_show: 'block'});
          this.setState({opacity: 0});
        });
      }
    }
  }

  render() {
    ReactGA.event({
        category: "Login",
        action: `Login to Kaitech from
          ${this.state.username}`,
        labels: 'Kaitech Login'
    });

    const { errors, error_show, isLoading } = this.state;
    const divOpacity = {
			opacity: this.state.opacity,
		};
    const divStyle = {
      display: error_show,
      backgroundColor: '#c5d6e6',
    };
    const title = i18n.t(k.LOGIN);

    return(
      <>
      <section className="sec container col-lg-4 col-sm-6">
        <h2 className="login-text">{i18n.t(k.LOGINTOKAI)}</h2>
        <hr></hr>
        <div className="login">
          {sessionStorage.getItem('username') === '' ||
          sessionStorage.getItem('username') === null?
            <form method="POST">
            <div className="form-group">
              <input
                value ={this.state.username}
                onChange={this.onChange}
                onClick={this.clearForm}
                type="text"
                name="username"
                id="username"
                className="form-control input-text"
                placeholder={i18n.t(k.USERNAME)}
                autoComplete="on"
              />
            </div>
              <div className="form-group">
                <input
                  value ={this.state.email}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="email"
                  name="email"
                  id="email"
                  className="form-control input-text"
                  placeholder="Email"
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <input
                  value ={this.state.password}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="password"
                  name="password"
                  className="form-control input-text"
                  placeholder={i18n.t(k.PASSWORD)}
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-lg"
                disabled={isLoading}
                type='submit'
                name='submit'
                onClick={this.onSubmit}>
                  {title}
                </button>
              </div>
              { this.state.ok ?
                <Billboard
                  text={i18n.t(k.SUCCESSLOGINUSER)}
                  message={'Visit KaiPixel.com.br/blog'}
                  image={'image1'}
                  history={this.props.history}
                />
              :
                null
              }
              { this.state.fault ?
                <Billboard
                  text={i18n.t(k.ERRORLOGINUSER)}
                  message={'Visit KaiPixel.com.br/blog'}
                  image={'image2'}
                  history={this.props.history}
                />
              :
                null
              }
              <div className="errors" style={divStyle}>
                <ul>{i18n.t(k.MISSING)}: {errors.map((error,key) => <li
                  key={key}>{error}</li>)}</ul>
              </div>

            </form>
          :
          null
          }
          <div style={divOpacity} className="load"></div>
        </div>
      </section>
      <Footer />
      </>
    )
  }
}

export default withRouter(LoginPage);
