import React from 'react'
import { Link } from 'react-router-dom';

import i18n from 'i18next';
import k from "./../i18n/keys";


import {UserContext} from '../App';

//import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";


import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

import './../assets/Footer.scss';

const topFunction = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const Footer = (props) => {
  return (
    <MDBFooter
      className="font-small" id="footer">
      <MDBContainer fluid className="text-center text-md-left container-wim">
        <MDBRow className="footer-background">
          <MDBCol md="12" className="grid-footer">
            <Link to="/about"
              className="nav-link footer-link grid-footer1">
              {i18n.t(k.ABOUT)}
            </Link>
            <Link to="/contact"
              className="nav-link footer-link grid-footer2">
              {i18n.t(k.CONTACTUS)}
            </Link>
            <Link to="/home"
              className="nav-link footer-link grid-footer3">
              {i18n.t(k.INDUSTRIES)}
            </Link>
            <Link to="/home"
              className="nav-link footer-link grid-footer4">
              {i18n.t(k.INVESTORRELATIONS)}
            </Link>
            <Link to="/home"
              className="nav-link footer-link grid-footer5">
              {i18n.t(k.PRIVACYPOLICY)}
            </Link>
            <Link to="/home"
              className="nav-link footer-link grid-footer6">
              {i18n.t(k.COOKIESPOLICY)}
            </Link>


          </MDBCol>
          <MDBCol md="12" className="grid-footer-social">
            <div className="center footer-link-social">
            <ul>
              <a rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCWelXkqNZG8lMbzh3o42VrQ"
                target="_blank"
                className="youtube social">
                <FontAwesomeIcon icon={faYoutube} size="1x"/>
              </a>
              <a rel="noopener noreferrer"
                href="https://www.facebook.com/kaitechconsultingbrazil"
                target="_blank"
                className="facebook social">
                <FontAwesomeIcon icon={faFacebook} size="1x"/>
              </a>
              <a rel="noopener noreferrer"
                href="https://www.twitter.com/kaitechconsult1"
                target="_blank"
                className="twitter social">
                <FontAwesomeIcon icon={faTwitter} size="1x"/>
              </a>
              <a rel="noopener noreferrer"
                href="https://www.instagram.com/kaitech_consulting"
                target="_blank"
                className="instagram social">
                <FontAwesomeIcon icon={faInstagram} size="1x"/>
              </a>
              <span id="myBtn" onClick={topFunction}
              className=" fa fa-angle-up arrowrow arrow-small" ></span>
            </ul>
            </div>

          </MDBCol>


          <MDBCol md="12" className="grid-footer-social">
            <div className="footer-copyright center">
              <MDBContainer fluid>
                &copy; {new Date().getFullYear()} Copyright:
                <a href="https://www.kaitech.com.br"> Kaitech.com.br V2.1.01</a>
                  {`<${UserContext._currentValue.user}>`}
                </MDBContainer>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

    </MDBFooter>

  );
}

export default Footer;
