module.exports = {
  "KAITECH": "KAITECH",
  "KAITECHCONSULTING": "KAITECHCONSULTING",
  "SOLUTION": "SOLUTION",
  "IOT": "IOT",
  "AUTOMOTIVE": "AUTOMOTIVE",
  "WEBDEV": "WEBDEV",
  "SEMICONDUCTORS": "SEMICONDUCTORS",
  "KAIABOUTMSG1": "KAIABOUTMSG1",
  "KAIABOUTMSG2": "KAIABOUTMSG2",
  "KAIABOUTMSG3": "KAIABOUTMSG3",
  "KAIABOUTMSG4": "KAIABOUTMSG4",
  "KAITECHHOME": "KAITECHHOME",
  "KAITECHHOME1": "KAITECHHOME1",
  "KAITECHHOME2": "KAITECHHOME2",
  "SERVICES": "SERVICES",
  "LOGINTOKAI": "LOGINTOKAI",
  "SIGNUPTOKAI": "SIGNUPTOKAI",
  "MKTDIGITAL": "MKTDIGITAL",
  "HOMOSERVICE": "HOMOSERVICE",
  "IOTSERVICE": "IOTSERVICE",
  "WIMSERVICE": "WIMSERVICE",
  "AUTOSERVICE": "AUTOSERVICE",
  "WEBSERVICE": "WEBSERVICE",
  "SEMISERVICE": "SEMISERVICE",
  "KAITECHSEMI": "KAITECHSEMI",
  "KAITECHSEMI1": "KAITECHSEMI1",
  "KAITECHSEMI2": "KAITECHSEMI2",
  "KAITECHWEB": "KAITECHWEB",
  "KAITECHWEB1": "KAITECHWEB1",
  "KAITECHWEB2": "KAITECHWEB2",
  "KAITECHAUTO": "KAITECHAUTO",
  "KAITECHAUTO1": "KAITECHAUTO1",
  "KAITECHAUTO2": "KAITECHAUTO2",
  "KAITECHWIM": "KAITECHWIM",
  "KAITECHWIM1": "KAITECHWIM1",
  "KAITECHWIM2": "KAITECHWIM2",
  "KAITECHIOT": "KAITECHIOT",
  "KAITECHIOT1": "KAITECHIOT1",
  "KAITECHIOT2": "KAITECHIOT2",
  "KAITECHMKT": "KAITECHMKT",
  "KAITECHMKT1": "KAITECHMKT1",
  "KAITECHMKT2": "KAITECHMKT2",
  "MUCHMORE": "MUCHMORE",
  "FIRSTNAME": "FIRSTNAME",
  "LASTNAME": "LASTNAME",
  "TITLE": "TITLE",
  "COMPANY": "COMPANY",
  "PHONE": "PHONE",
  "OPTIONS": "OPTIONS",
  "OPTION": "OPTION",
  "NEWSLETTER": "NEWSLETTER",
  "TEXTAREA": "TEXTAREA",
  "ENTRECONTATO": "ENTRECONTATO",
  "SOURCE": "SOURCE",
  "REMARKS": "REMARKS",




  "WIM": "WIM",
  "HEADCONC": "HEADCONC",
  "HEADCONCGRAPH": "HEADCONCGRAPH",
  "SEARCH": "SEARCH",
  "ABOUT_US": "ABOUT_US",
  "ABOUT": "ABOUT",
  "ABOUTMSG1": "ABOUTMSG1",
  "ABOUTMSG2": "ABOUTMSG2",
  "ABOUTMSG3": "ABOUTMSG3",
  "LOGIN": "LOGIN",
  "LOGINTOWIM": "LOGINTOWIM",
  "MAP": "MAP",
  "MAPS": "MAPS",
  "MAPGROUPS": "MAPGROUPS",
  "MAPCONCENTRATORS": "MAPCONCENTRATORS",
  "MAPSENSORS": "MAPSENSORS",
  "DATA": "DATA",
  "SIGNUP": "SIGNUP",
  "SIGNUPTOWIM": "SIGNUPTOWIM",
  "SETTINGS": "SETTINGS",
  "USERS": "USERS",
  "USER": "USER",
  "USERCREATED": "USERCREATED",
  "NEWGROUP": "NEWGROUP",
  "GROUP": "GROUP",
  "GROUPS": "GROUPS",
  "GROUPDATA": "GROUPDATA",
  "GROUPCREATED": "GROUPCREATED",
  "GROUPNAME": "GROUPNAME",
  "GROUPID": "GROUPID",
  "NEWCONCENTRATOR": "NEWCONCENTRATOR",
  "CONCENTRATOR": "CONCENTRATOR",
  "CONCENTRATORS": "CONCENTRATORS",
  "CONCENTRATORDATA": "CONCENTRATORDATA",
  "CONCENTRATORCREATED": "CONCENTRATORCREATED",
  "NEWSENSOR": "NEWSENSOR",
  "SENSOR": "SENSOR",
  "SENSORS": "SENSORS",
  "SENSORDATA": "SENSORDATA",
  "SENSORSBYCONC": "SENSORSBYCONC",
  "SENSORCREATED": "SENSORCREATED",
  "NEWMEASURE": "NEWMEASURE",
  "MEASURE": "MEASURE",
  "MEASURES": "MEASURES",
  "MEASURESBYSBYC": "MEASURESBYSBYC",
  "MEASURESBYSENSOR": "MEASURESBYSENSOR",
  "MEASURECREATED": "MEASURECREATED",
  "LOGS": "LOGS",
  "LOGOUT": "LOGOUT",
  "INFRASTRUCTURE": "INFRASTRUCTURE",
  "PREVIOUS": "PREVIOUS",
  "NEXT": "NEXT",
  "NOTLOGGED": "NOTLOGGED",
  "SUBSCRIBERS": "SUBSCRIBERS",




  "USERNAME": "USERNAME",
  "ROLE": "ROLE",
  "MESSAGE": "MESSAGE",
  "CREATED": "CREATED",
  "LASTLOGIN": "LASTLOGIN",
  "ADMINISTRATOR": "ADMINISTRATOR",
  "DELETE": "DELETE",
  "UPDATE": "UPDATE",
  "CANCEL": "CANCEL",
  "SAVENEW": "SAVENEW",
  "BACK": "BACK",
  "PASSWORD": "PASSWORD",
  "PASSWORDCONFIRMATION": "PASSWORDCONFIRMATION",
  "NOTAUTHORIZED": "NOTAUTHORIZED",
  "NOTAUTHORIZEDPLEASELOGIN": "NOTAUTHORIZEDPLEASELOGIN",
  "NOTFOUND": "NOTFOUND",
  "ERROR": "ERROR",
  "ERRORCREATIONUSER": "ERRORCREATIONUSER",
  "ERRORLOGINUSER": "ERRORLOGINUSER",
  "ERRORNEWSLETTER": "ERRORNEWSLETTER",
  "SUCCESSCREATIONUSER":"SUCCESSCREATIONUSER",
  "SUCCESSLOGINUSER":"SUCCESSLOGINUSER",
  "MISSING": "MISSING",
  "WIMINNUMBERS": "WIMINNUMBERS",
  "BY": "BY",
  "MEASUREDATE": "MEASUREDATE",
  "ADDMEASUREWIM": "ADDMEASUREWIM",
  "ADDSENSORWIM": "ADDMSENSORWIM",
  "ADDCONCENTRATORWIM": "ADDCONCENTRATORWIM",
  "ADDGROUPTOWIM": "ADDGROUPTOWIM",
  "ADDUTILITYTOWIM": "ADDUTILITYTOWIM",
  "CREATENEWCONC": "CREATENEWCONC",
  "CREATENEWGROUP": "CREATENEWGROUP",
  "CONSUMPTION": "CONSUMPTION",
  "HYDROMETER": "HYDROMETER",
  "LITERS": "LITERS",
  "GALLONS": "GALLONS",
  "TIME": "TIME",
  "VERSUS": "VERSUS",
  "LATITUDE": "LATITUDE",
  "LONGITUDE": "LONGITUDE",
  "ADDRESS": "ADDRESS",
  "CITY": "CITY",
  "STATE": "STATE",
  "COUNTRY": "COUNTRY",
  "ZIPCODE": "ZIPCODE",
  "CONSTANT": "CONSTANT",
  "ACTIVATION": "ACTIVATION",
  "DELETECONCENTRATOR": "DELETECONCENTRATOR",
  "DELETESENSOR": "DELETESENSOR",
  "DELETEUTILITY": "DELETEUTILITY",
  "DELETEUSER": "DELETEUSER",
  "DELETEMEASURE": "DELETEMEASURE",
  "ACTIVATEDSENSORS": "ACTIVATEDSENSORS",
  "OBS": "OBS",
  "MEASUREEND": "MEASUREEND",
  "MEASURESTART": "MEASURESTART",
  "SENSORTIMEEND": "SENSORTIMEEND",
  "SENSORTIME": "SENSORTIME",
  "PASSWORDNOTMATCH": "PASSWORDNOTMATCH",
  "MSGSIGNUP": "MSGSIGNUP",
  "SITENAME": "SITENAME",
  "CONNECTION": "CONNECTION",
  "MAXHYDROMETERS": "MAXHYDROMETERS",
  "LANGUAGES": "LANGUAGES",
  "ENGLISH": "ENGLISH",
  "PORTUGUESE": "PORTUGUESE",
  "CHANGELANGUAGE": "CHANGELANGUAGE",

  "CSO": "CSO",
  "UNIFIEDMANAGEMENT": "UNIFIEDMANAGEMENT",
  "PERPAGE": "PERPAGE",
  "INVALIDENTRY": "INVALIDENTRY",
  "INDUSTRIES": "INDUSTRIES",
  "INVESTORRELATIONS": "INVESTORRELATIONS",
  "CONTACTUS": "CONTACTUS",
  "PRIVACYPOLICY": "PRIVACYPOLICY",
  "COOKIESPOLICY": "COOKIESPOLICY",
  "TERMSOFSERVICE": "TERMSOFSERVICE",
  "NAME": "NAME",
  "ID": "ID",
  "COLOR": "COLOR",
  "CREATION": "CREATION",
  "USERID": "USERID",
  "OWNER": "OWNER",
  "NOMEASURES": "NOMEASURES",
  "GENERAL": "GENERAL",
  "STATUS": "STATUS",
  "UNPROCESSABLE": "UNPROCESSABLE",
  "GENERALALARM": "GENERALALARM",
  "LEAKAGE": "LEAKAGE",
  "FRAUD": "FRAUD",
  "BLOCKED": "BLOCKED",
  "CONNECTED": "CONNECTED",
  "NORMALFLOW": "NORMALFLOW",
  "REVERSE": "REVERSE",
  "UNDERFLOW": "UNDERFLOW",
  "OVERFLOW": "OVERFLOW",
  "STOPPED": "STOPPED",
  "LASTCOMM": "LASTCOMM",
  "LAST": "LAST",
  "ITEMS": "ITEMS",
  "RANGESELECTION": "RANGESELECTION",
  "CONSSELECTION": "CONSSELECTION",
  "STARTDATE": "STARTDATE",
  "ENDDATE": "ENDDATE",
  "SELECTONE": "SELECTONE",
  "LEGENDS": "LEGENDS",
  "DATUM": "DATUM",
  "NOCONNECTION": "NOCONNECTION",
  "FILTER": "FILTER",
  "FILTERCONS": "FILTERCONS",
  "REPORTS": "REPORTS",
  "UTILITY": "UTILITY",
  "UTILITIES": "UTILITIES",
  "COD": "COD",
  "ACTUALMEASDATE": "ACTUALMEASDATE",
  "PREVIOUSMEASDATE": "PREVIOUSMEASDATE",
  "MEASDAY": "MEASDAY",
  "ACTIVE": "ACTIVE",
  "UTILITYNAME": "UTILITYNAME",
  "UTILITYCREATED": "UTILITYCREATED",
  "NEWUTILITY": "NEWUTILITY",
  "TRUE": "TRUE",
  "FALSE": "FALSE",
  "DISCONNECTED": "DISCONNECTED",
  "WARNINGS": "WARNINGS",
  "FRAUDDETECTED": "FRAUDDETECTED",
  "DAYS": "DAYS",
  "CLEAR": "CLEAR",



};
