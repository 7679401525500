import React, { Component } from 'react';
import { Link , withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

import i18n from 'i18next';
import k from "./../i18n/keys";

import axios from 'axios';
import {UserContext} from '../App';


// import logo from '../assets/images/kaitech_logo_sea.jpg';
import logo from '../assets/images/Kaitech_header.jpg';
import './../assets/Navbar.scss';
import flagus from '../assets/images/us.svg';
import flagbr from '../assets/images/br.svg';

class Navbar extends Component {
  constructor(props) {
    super(props)

    //const a = "\ud83c\uddfa";
    //const d = "\ud83c\uddE7";
    //const flag_en = a + "\ud83c\uddf8";
    //const flag_br = d + "\ud83c\uddF7";


    this.state = {
      logged: props.logged,
      username: props.username,
      pathname: '//',
      flag_br: "\uD83C\uDDE7\uD83C\uDDF7",
      flag_en: "\uD83C\uDDFA\uD83C\uDDF8",
      lng: sessionStorage.getItem('lng'),
      flag: sessionStorage.getItem('flag'),
    }



    this.handleClick = this.handleClick.bind(this);

  }

  handleClick = (lang) => {

  ReactGA.event({
  category: "Language",
  action: `Changed language to ${lang} - ${this.props.username}`,
  labels: 'Kaitech Language'
  });

    if (this.props.logged !== false) {
        i18n.changeLanguage(lang);
        sessionStorage.setItem('lng', lang);
        this.setState({lng: lang})
        this.props.history.push(window.location.pathname);
    } else {
      i18n.changeLanguage(lang);
      sessionStorage.setItem('lng', lang);
      this.setState({lng: lang})
      this.props.history.push(window.location.pathname);
    }

    this.handleBar = this.handleBar.bind(this);
}

getGeoInfo = () => {
  axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      this.setState({
          countryName: data.country_name,
          region: data.region,
          city: data.city,
          ip: data.ip
      });
  }).catch((error) => {
      console.log(error);
  });
};

  handleOnClick = (e) => {
    if (e.target.id === 'logout') {
      const { ip } = this.state;
      ReactGA.event({
          category: "Logout",
          action: `Logout from IP:${ip} -
          ${this.state.username}`,
          labels: 'Kaitech Logout'
      });
      this.props.history.push('/');
    } else {
      if (e.target.id === 'login') {
        const { ip } = this.state;
        ReactGA.event({
            category: "Login",
            action: `Login from IP:${ip} -
            ${this.state.username}`,
            labels: 'Kaitech Login'
        });
      }
    }
    this.props.logout(e.target.id);
  }

  componentDidMount() {
    this.getGeoInfo();
    let x = document.getElementsByClassName('navbar-collapse')[0];
    if (x){
      x.classList.toggle("collapse");
    }
    let current_flag = '';
    switch (sessionStorage.getItem('lng')) {
      case 'pt-BR':
        current_flag = flagbr;
      break;
      case 'en':
        current_flag = flagus;
      break;
      default:
      current_flag = flagus;
    }
    this.setState({flag: current_flag});
    sessionStorage.setItem('flag', current_flag);
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevStates.lng !== this.state.lng){
      //const a = "\ud83c\uddfa";
      //const d = "\ud83c\uddE7";
      //const flag_en = a + "\ud83c\uddf8";
      //const flag_br = d + "\ud83c\uddF7";

      let current_flag = '';
      switch (this.state.lng) {
        case 'pt-BR':
          current_flag = flagbr;
        break;
        case 'en':
          current_flag = flagus;
        break;
        default:
        current_flag = flagus;
      }
        sessionStorage.setItem('flag', current_flag);
        this.setState({flag: current_flag});
    }
  }

  handleOnSubmit = (e) => {
    e.preventDefault();
    // if (this.state.search !== '' && this.state.search !== null) {
    //   sessionStorage.setItem('search', this.state.search);
    //   this.setState({search: ''});
    //   this.props.history.push('/delete');
    // }
  }

  handleOnSubmit1 = (e) => {
    if (e.target.dataset.services !== undefined) {
      ReactGA.event({
          category: "Services",
          action: `Access to Services from
            ${this.state.username} - ${e.target.dataset.services}`,
          labels: 'Kaitech Services'
      });
    }
    e.preventDefault();
    if (window.location.href.split('/')[3] !== '') {

    }
    let pos;
    let top;
    if (e.target.dataset.services !== undefined) {
      if (document.getElementById(e.target.dataset.services) !== null) {
        top = document.getElementById('top').getBoundingClientRect().top;
        pos = document.getElementById(e.target.dataset.services)
        .getBoundingClientRect();
        document.body.scrollTop = pos.top;
        document.documentElement.scrollTop =
        pos.top - top - window.innerHeight / 7;
      }
    }
  }

  handleOnChange = (e) => {
    this.setState({[e.target.name]: e.target.value });
  }

  handleBar = (e) => {
    var x = document.getElementsByClassName('navbar-collapse')[0];
    x.classList.toggle("collapse");
    window.onresize = function() {
      if (window.innerWidth > 1024) {
        x = document.getElementsByClassName('navbar-collapse')[0];
        x.classList.remove("collapse");
        window.onresize = null;
      }
    }
  }

  render() {

    return(
      <React.Fragment>
        <nav

          className="navbar navbar-expand-lg navbar-light p-0 navbar-right fixed-top navbar-background bg-faded navbar-transparent"
        >
          <Link to="/home" className="nav-link">
            <img
              className="logo" id='1'
              src= {logo}
              alt="Kaitech Logo"/>
          </Link>
          <div className="dropdown dropdown-flag">
            <div
              className="grid-flag"
              >
              <a className="nav-link btn-link dropdown-nav"
              href="about:blank"
              data-toggle="dropdown"
              >{i18n.t(k.LANGUAGES)}
              </a>
              <img className="img-header"
                src={sessionStorage.getItem('flag')} alt="Selected Flag"/>
            </div>
            <div className="dropdown-menu dropdown-menu-flag ">
              <button className="dropdown-item-flag"
                onClick={()=>this.handleClick('en')}>
                {i18n.t(k.ENGLISH)} - <span
                  role="img" aria-label="USA" alt="US Flag">
                  <img className="img-flag" src={flagus} alt="US Flag"/>
                </span>
              </button>

              <button className="dropdown-item-flag"
                onClick={()=>this.handleClick('pt-BR')}>
                {i18n.t(k.PORTUGUESE)} - <span
                role="img" aria-label="Brazil">
                <img className="img-flag" src={flagbr} alt="BR Flag"/></span>
              </button>
            </div>
          </div>
          <p
          className="navbar-logo-text"
          data-test="navbar-nav"
          >{i18n.t(k.KAITECHCONSULTING)}</p>

          <button
            className="toggle-collapse"
            onClick={this.handleBar}
            id="hamburguer">
            <i className="fas fa-bars fa-lg"></i>
          </button>

          <div className="collapse navbar-collapse \
                          justify-content-end nav-col \
                          menu-color"
                          id="navbarSupportedContent">

          <form className="navbar-nav form-inline mr-md-5 my-2 my-lg-0"
            onClick={this.handleOnSubmit1}>
            <div className="dropdown">
              <a className="nav-link dropdown-toggle btn-link dropdown-services"
                href="#top" id="servicesDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {i18n.t(k.SERVICES)}
              </a>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="kaitech">
                  {'Kaitech'}
                </Link>
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="mktdigital">
                  {i18n.t(k.MKTDIGITAL)}
                </Link>
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="iot">
                  {i18n.t(k.IOTSERVICE)}
                </Link>
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="wim">
                  {i18n.t(k.WIMSERVICE)}
                </Link>
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="automotive">
                  {i18n.t(k.AUTOSERVICE)}
                </Link>
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="webdevelopment">
                  {i18n.t(k.WEBSERVICE)}
                </Link>
                <Link to="/home/#top"
                  className="btn dropdown-item menu-color"
                  data-services="semiconductor">
                  {i18n.t(k.SEMISERVICE)}
                </Link>
              </div>
            </div>
          </form>

          <a href="https://www.kaipixel.com" target="_blank"
            id='kaypixel' rel="noopener noreferrer"
            className="nav-link btn btn-link menu-color">
            {'Blog'}
            </a>

          <Link to="/dashboard"
            className="nav-link btn btn-link menu-color">
            {'Dashboard'}
          </Link>

          <Link to="/about"
            className="nav-link btn btn-link menu-color">
            {i18n.t(k.ABOUT)}
          </Link>

          <Link to="/contact"
            className="nav-link btn btn-link menu-color">
            {i18n.t(k.CONTACTUS)}
          </Link>

          <Link to="/signup"
            className="nav-link btn btn-link menu-color">
            {i18n.t(k.SIGNUP)}
          </Link>

          {this.props.logged === false ?
            <Link to="/login" onClick={this.handleOnClick} id='login'
              className="nav-link btn btn-link my-2 my-sm-0 menu-color">
              {i18n.t(k.LOGIN)}{this.state.username}
            </Link>
          :
            null
          }

            {this.props.logged === true ?
              <>


              <div className="dropdown drop-z-index" >
                <a className="nav-link dropdown-toggle \
                  btn-link dropdown-services"
                  href="#top" id="services"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  {'<'+ UserContext._currentValue.user + '>'}
                </a>
                <div
                  className="dropdown dropdown-menu"
                  aria-labelledby="services">
                  <Link to="/users" id='users'
                    className="btn my-2 my-sm-0 dropdown-item menu-color">
                    {i18n.t(k.USERS)}
                  </Link>
                  <Link to="/newsletter" id='newsletter'
                    className="btn my-2 my-sm-0 dropdown-item menu-color">
                    {i18n.t(k.NEWSLETTER)}
                  </Link>
                  <a href="https://www.kaipixel.com" target="_blank"
                    id='kaypixel' rel="noopener noreferrer"
                    className="btn my-2 my-sm-0 dropdown-item menu-color">
                    {'KaiPixel Blog'}
                  </a>
                  <Link to="/logout" onClick={this.handleOnClick} id='Logout'
                    className="btn my-2 my-sm-0 dropdown-item menu-color">
                    {i18n.t(k.LOGOUT)}
                  </Link>
                  <Link to="/blog"
                    className="nav-link btn btn-link menu-color">
                    {'Connection'}
                  </Link>
                </div>
              </div>
              </>
            :
              null
            }
          </div>
        </nav>
      </React.Fragment>

    )
  }
}

export default withRouter(Navbar);
