import React, { Component } from 'react';

import i18n from 'i18next';
import k from "./../i18n/keys";

import ReactGA from 'react-ga';

import { UserContext } from '../App';

import axios from 'axios';
import './../assets/kaitech.scss';
import './../assets/wim.scss';
import Billboard from './Billboard'

import Footer from './Footer';

class SignupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: UserContext._currentValue.user,
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      password_confirmation: '',
      admin: "",
      role: '',
      aux1: '',
      aux2: '',

      ok: false,
      fault: false,
      errors: [],
      error_show: 'none',
      loading: false,
      isLoading: false,
      opacity: 0,
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  validate_form = () => {
    this.setState({errors: []});
    let array = [];
    if (this.state.username === ''){
      array.push(` ${i18n.t(k.USERNAME)}.\r\n`);
    };
    if (this.state.firstname === ''){
      array.push(` ${i18n.t(k.FIRSTNAME)}.\r\n`);
    };
    if (this.state.lastname === ''){
      array.push(` ${i18n.t(k.LASTNAME)}.\r\n`);
    };
    if (this.state.email === ''){
      array.push(" Email.\r\n");
    };
    if (this.state.password === ''){
      array.push(` ${i18n.t(k.PASSWORD)}.\r\n`);
    };
    if (this.state.password_confirmation === ''){
      array.push(` ${i18n.t(k.PASSWORDCONFIRMATION)}.\r\n`);
    };
    if (array.length === 0) {
      if (this.state.password === this.state.password_confirmation) {
        this.setState({errors: array})
        return true
      }
    }
    if (this.state.password !== this.state.password_confirmation) {
      array.push(`${i18n.t(k.PASSWORDNOTMATCH)}`);
    };
    this.setState({errors: array});
    this.setState({error_show: 'block'});
    return false;
  }

  clearForm = (e) => {
    this.setState({errors: []});
    this.setState({error_show: 'none'});
  }

  onChange(e) {
    this.setState({errors: []});
    const value = e.target.type === 'checkbox' ?
    e.target.checked : e.target.value;
    this.setState({ [e.target.name]: e.target.value});
    if (value === true) {
      this.setState({ admin: value});
    }
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  	this.setState({opacity: 0})
    document.getElementById('username').focus();
	}



  async onSubmit(e) {

    e.preventDefault();

    let admin = '';
    if (this.state.admin === true) {
      admin = 'admin'
    } else {
      admin = 'user'
    }

    var config_signup = {
        headers: {
          'Content-Type': 'application/json'
                }
    };

    var bodyParameters_signup = {
      user: {
        username: this.state.username,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        password: this.state.password,
        role: admin,
        aux1: this.state.aux1,
        aux2: this.state.aux2,
      }
    };

   if (this.validate_form()) {
     this.setState({opacity: 1});
     const url = 'https://kaitech.herokuapp.com/users';
     await axios.post(url, bodyParameters_signup, config_signup)
     .then(res => {
       this.setState({
         ok: true,
         // fault: false
       })

       this.setState({opacity: 0});
       //alert('User created!');
       //this.props.history.push('/home');
      })
      .catch((error)=>{
        this.setState({
          fault: true,
          // ok: false
        })

        this.setState({opacity: 0});
        console.log('Error - ', error);
      });
    }
  }

  render() {
    ReactGA.event({
        category: "Signup",
        action: `Signup to Kaitech from
          ${this.state.username}`,
        labels: 'Kaitech Signup'
    });

    const {errors, error_show, isLoading} = this.state;
    const divOpacity = {
			opacity: this.state.opacity,
		};
    const divStyle = {
      display: error_show,
      backgroundColor: '#c5d6e6',
    };

    const title = i18n.t(k.SIGNUP);
    const title_clear = i18n.t(k.CLEAR);
    return (
      <>
      <section className="sec container col-lg-4 col-sm-6">
        <h2 className="signup-text">{i18n.t(k.SIGNUPTOKAI)}</h2>
        <hr></hr>
        <div className="signup error_show">
          <form onSubmit={this.onSubmit}>
            <div className="row signup-data">
              <div className="form-group">
                <input
                  value ={this.state.username}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="username"
                  id="username"
                  className="form-control"
                  placeholder={i18n.t(k.USERNAME)}
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <input
                  value ={this.state.firstname}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="form-control"
                  placeholder={i18n.t(k.FIRSTNAME)}
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <input
                  value ={this.state.lastname}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="form-control"
                  placeholder={i18n.t(k.LASTNAME)}
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <input
                  value ={this.state.email}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <input
                  value ={this.state.password}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder={i18n.t(k.PASSWORD)}
                  autoComplete="on"
                />
              </div>
              <div className="form-group">
                <input
                  value ={this.state.password_confirmation}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="password"
                  name="password_confirmation"
                  className="form-control"
                  placeholder={i18n.t(k.PASSWORDCONFIRMATION)}
                  autoComplete="on"
                />
              </div>
              <div className="form-group ad">
                <input
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="checkbox"
                  name="admin"
                />
                <label className="admin">{i18n.t(k.ADMINISTRATOR)}</label>
              </div>
            </div>
            <br />
            <br />
            <div className="signup-buttons">
              <div className="form-group signup-clear">
                <button className="btn btn-primary btn-lg"
                disabled={isLoading}
                name={title_clear}
                onClick={this.clearFormFields}>
                  {title_clear}
                </button>
              </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-lg"
                  disabled={isLoading}
                  type='submit'
                  name='submit'>
                    {title}
                  </button>
                </div>
            </div>
            { this.state.ok ?
              <Billboard
                text={i18n.t(k.SUCCESSCREATIONUSER)}
                message={'Visit KaiPixel.com.br/blog'}
                image={'image1'}
                history={this.props.history}
              />
            :
              null
            }
            { this.state.fault ?
              <Billboard
                text={i18n.t(k.ERRORCREATIONUSER)}
                message={'Visit KaiPixel.com.br/blog'}
                image={'image2'}
                history={this.props.history}
              />
            :
              null
            }
            <div className="errors" style={divStyle}>
              <ul>{i18n.t(k.MISSING)}: {errors.map((error,key) =>
                <li key={key}>{error}</li>)}</ul>
            </div>
          </form>
        </div>
        <div style={divOpacity} className="load"></div>
      </section>
      <Footer />
      </>
    )
  }
}

export default SignupPage;
