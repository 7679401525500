import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LazyLoad from 'react-lazyload'
import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';
import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Dashboard from './components/Dashboard1';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import Contact from './components/Contact';
import Newsletter from './components/Newsletter';
import Users from './components/Users';
import Dash from './components/Dash';
//import Footer from './components/Footer';
import Component404 from './components/Component404';
import Blog from './components/Blog';

import './App.css';

export const UserContext = React.createContext('');

const trackingId = "UA-177783835-1"; // your Google Analytics tracking ID
ReactGA.initialize(trackingId, {testMode: true});
ReactGA.set({
  userId: 'ascalmon',
  // any data that is relevant to the user session
  // that you would like to track with google analytics
})

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname });//Update the user's current page
  ReactGA.pageview(location.pathname);//Record a pageview for the given page
});

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      logged: false,  // This state shows if there is an username logged
      username: '',
      token: '',
    }

    sessionStorage.setItem('lng', 'pt-BR');

  }

  async componentDidMount() {
    this.setState({username: ''});
    this.setState({logged: false});
    this.setState({token: ''});
  }

  handleLogOut = (index) => {
    if (index === 'Logout') {
      //console.log('Logout');
      this.setState({logged: false});
      this.setState({username: ''});
      this.setState({token: ''});
      return true;
    } else if (index !== 'login'){
      this.setState({logged: true});
      this.setState({username: index});
    }
  }

  handleToken = (token) => {
    this.setState({token: token});
  }

  // handleLogin = (index) => {
  //   //console.log('App login', index, this.state.logged, this.state.username)
  // }

  render() {

    return (

      <Router >
        <div className="container-kai col-12 root">
          <UserContext.Provider
            value = {{user: this.state.username, token: this.state.token}} >
            <Navbar logged={this.state.logged} logout={this.handleLogOut}
              username={this.state.username} />
          </UserContext.Provider>
          <Switch>
            <UserContext.Provider
              value = {{user: this.state.username, token: this.state.token}} >
              <LazyLoad height={200}>
              <Route exact path="/" component= {Home} />
              <Route path="/home" component= {Home} />
              <Route path="/about" render =
                {() => <About name="Kaitech Consulting" /> } />
              <Route path="/dashboard" render =
                {() => <Dashboard name="Kaitech Consulting" /> } />
              <Route path="/login" render =
                {() => <LoginPage token={this.handleToken}
              current_user={this.handleLogOut} /> } />
              <Route path="/logout" component= {Home} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/blog" component={Blog} />
              <Route path="/contact" component={Contact} />
              <Route path="/newsletter" component={Newsletter} />
              <Route path="/users" component={Users} />
              <Route path="/dash" component={Dash} />
              </LazyLoad>
            </UserContext.Provider>
              <Route path='*' component={Component404} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
