import React, { Component } from "react";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';


import ReactGA from 'react-ga';

import { UserContext } from '../App';

import Footer from './Footer';

import i18n from 'i18next';
import k from "./../i18n/keys";

import axios from 'axios';
import './../assets/Navbar.scss';
import './../assets/kaitech.scss';
import './../assets/wim.scss';
//import { Billboard } from './Billboard.js';

//import img4 from "../assets/images/water4.jpg";
import img1 from "../assets/images/mktdigital4.jpg";
import img2 from "../assets/images/iot2.jpg";
import img3 from "../assets/images/hydrodam3.jpg";
import img4 from "../assets/images/automotive.jpg";
import img5 from "../assets/images/webdevelopment4.jpg";
import img6 from "../assets/images/semiconductor.jpg";
import img7 from "../assets/images/kaitech2-crop.jpg";
import img8 from "../assets/images/semiconductor3.jpg";
import img9 from "../assets/images/iot3.jpg";
import img10 from "../assets/images/hydrodam2.jpg"
import img11 from "../assets/images/automotive2.jpg";
import img12 from "../assets/images/webdevelopment2.jpg";
import img13 from "../assets/images/mktdigital2.jpg";
import help from '../assets/images/webdevelopment3.jpg';

//import { UserContext } from '../App';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: UserContext._currentValue.user,
      data: [],

      countryName: '',
      region: '',
      city: '',
      ip: '',
      open: 'hidden',
      opacity: 1,
    }

    window.addEventListener('scroll', function() {
      // if(window.scrollY < 10) {
      //   document.getElementsByClassName('navbar-background')
      //   [0].style.backgroundColor= 'transparent';
      //           document.getElementsByClassName('footer')
      //           [0].style.position = 'fixed';
      // } else {
      //   // document.getElementsByClassName('navbar-background')
      //   // [0].style.backgroundColor = '#c5d6e6';
      //   document.getElementsByClassName('navbar-background')
      //   [0].style.backgroundColor = 'tranparent';
      //   document.getElementsByClassName('footer')[0].style.position = 'sticky';
      // }
      var hamburguer = document.getElementById('hamburguer');
      hamburguer.style.backgroundColor = 'rgba(23, 23, 23, 0.3)';

      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }

    }, {passive: false});

  }

  getGeoInfo = () => {
  axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      this.setState({
          countryName: data.country_name,
          region: data.region,
          city: data.city,
          ip: data.ip
      });
  }).catch((error) => {
      console.log(error);
  });
};

topFunction = () => {
  //var t = window.scrollY + document.querySelector('#kaitech').getBoundingClientRect().top
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

  async componentDidMount() {
    this.getGeoInfo();
    AOS.init({
       easing: 'ease-out-back',
       duration: 1800,
       delay: 100,
       //once: true,
       disable: 'mobile'
     });

     var x = document.getElementsByTagName('html')[0].style;
     x.scrollBehavior = 'smooth';
     document.body.scrollTop = 0;
     document.documentElement.scrollTop = 0;
     // var x = document.getElementById('top').style;
     // x.visibility = 'visible';
     //this.setState({open: 'visible'});
  }

  componentWillUnmount() {
    var x = document.getElementsByTagName('html')[0].style;
    x.scrollBehavior = 'auto';
    //var x = document.getElementById('services').style;
    //x.visibility = 'hidden';
  }

  topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleImageLoaded = () => {
    var x = document.getElementById('top').style;
    x.visibility = 'visible';
    this.setState({opacity: 0})
    //alert('Image loaded');
  }

  render() {
    const { countryName, region, city, ip} = this.state;
    ReactGA.event({
        category: "Home",
        action: `Access from ${countryName}, - ${city} - ${region} - IP:${ip} \
        ${this.state.username}`,
        labels: 'Kaitech Home'
      });

      const divOpacity = {
        opacity: this.state.opacity,
      };

    return (
      <>
      <div id="top" className="top-load">
        <React.Fragment>
        <div id="myCarousel" className="carousel slide carousel-fade"
        data-ride="carousel">
          <ul className="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0"
            className="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
            <li data-target="#myCarousel" data-slide-to="3"></li>
            <li data-target="#myCarousel" data-slide-to="4"></li>
            <li data-target="#myCarousel" data-slide-to="5"></li>
          </ul>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={img4}
                className="img-fluid"
                alt="Kaitech Digital Marketing"
                onLoad={this.handleImageLoaded.bind(this)}
              />
              <div className="carousel-caption carousel-text">
                {/*<p className="carousel-text">{i18n.t(k.KAITECHCONSULTING)}</p>*/}
                <p className="carousel-text">{i18n.t(k.AUTOMOTIVE)}</p>
              </div>
            </div>

            <div className="carousel-item">
              <img src={img2}
                className="img-fluid"
                alt="IoT"
              />
              <div className="carousel-caption carousel-text">
                <p className="carousel-text">{i18n.t(k.IOT)} </p>
              </div>
            </div>

            <div className="carousel-item">
              <img src={img3} className="img-fluid" alt="Hydrodam"/>
              <div className="carousel-caption  carousel-text">
                <p className="carousel-text">{i18n.t(k.UNIFIEDMANAGEMENT)}</p>
              </div>
            </div>

            <div className="carousel-item">
              <img src={img1} className="img-fluid" alt="Hydrodam"/>
              <div className="carousel-caption  carousel-text">
                <p className="carousel-text">{i18n.t(k.SOLUTION)}</p>
              </div>
            </div>

            <div className="carousel-item">
              <img src={img5} className="img-fluid" alt="Web development"/>
              <div className="carousel-caption  carousel-text">
                <p className="carousel-text">{i18n.t(k.WEBDEV)}</p>
              </div>
            </div>

            <div className="carousel-item">
              <img src={img6} className="img-fluid" alt="Semiconductors"/>
              <div className="carousel-caption  carousel-text">
                <p className="carousel-text">{i18n.t(k.SEMICONDUCTORS)}</p>
              </div>
            </div>

          </div>
          <a className="carousel-control-prev" href="#myCarousel" role="button"
            data-slide="prev">
            <span className="carousel-control-prev-icon"
              aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#myCarousel" role="button"
            data-slide="next">
            <span className="carousel-control-next-icon"
            aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <hr />
        <h1 className="center page-header">
          {i18n.t(k.KAITECH)}
        </h1>
        </React.Fragment>

        <div className="banner-post" id="kaitech">
          <div className="banner-post__img">
            <img className="" src={img7} alt="" />
          </div>
          <div className="banner-post__info">

            <h1 className="banner-post__title">Kaitech Consulting</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHHOME)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHHOME1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHHOME2)}
              </p>
            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
            <Link to="#top" className="banner-post__cta \
              fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
            </Link>
          </div>
        </div>

        <div className="banner-post" id="mktdigital"
        data-aos="fade-left"
        data-aos-duration='2000'>
          <div className="banner-post__info">
            <h1 className="banner-post__title">{i18n.t(k.MKTDIGITAL)}</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHMKT)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHMKT1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHMKT2)}
              </p>
            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
            <Link to="#top" className="banner-post__cta \
              fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
            </Link>
          </div>
          <div className="banner-post__imgr">
            <img className="" src={img13} alt="" />
          </div>
        </div>

        <div className="banner-post" id="iot"
        data-aos="fade-right"
        data-aos-duration='2000'>
          <div className="banner-post__img">
            <img className="" src={img9} alt="" />
          </div>
          <div className="banner-post__info">

            <h1 className="banner-post__title">{i18n.t(k.IOTSERVICE)}</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHIOT)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHIOT1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHIOT2)}
              </p>
            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
            <Link to="#top" className="banner-post__cta \
              fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
            </Link>
          </div>
        </div>

        <div className="banner-post" id="wim"
        data-aos="fade-left"
        data-aos-duration='2000'>
          <div className="banner-post__info">
            <h1 className="banner-post__title">{i18n.t(k.WIMSERVICE)}</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHWIM)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHWIM1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHWIM2)}
              </p>
            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
            <Link to="#top" className="banner-post__cta \
              fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
            </Link>
          </div>
          <div className="banner-post__imgr">
            <img className="" src={img10} alt="" />
          </div>
        </div>

        <div className="banner-post" id="automotive"
        data-aos="fade-right"
        data-aos-duration='2000'>
          <div className="banner-post__img">
            <img className="" src={img11} alt="" />
          </div>
          <div className="banner-post__info">
            <h1 className="banner-post__title">{i18n.t(k.AUTOSERVICE)}</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHAUTO)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHAUTO1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHAUTO2)}
              </p>

            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
            <Link to="#top" className="banner-post__cta \
              fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
            </Link>
          </div>
        </div>

        <div className="banner-post" id="webdevelopment"
        data-aos="fade-left"
        data-aos-duration='2000'>
          <div className="banner-post__info">
            <h1 className="banner-post__title">{i18n.t(k.WEBSERVICE)}</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHWEB)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHWEB1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHWEB2)}
              </p>
              <Link to="#top" className="banner-post__cta \
                fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
              </Link>
            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
          </div>
          <div className="banner-post__imgr">
            <img className="" src={img12} alt="" />
          </div>
        </div>

        <div className="banner-post" id="semiconductor"
        data-aos="fade-up"
        data-aos-duration='2000'>
          <div className="banner-post__img">
            <img className="" src={img8} alt="" />
          </div>
          <div className="banner-post__info">

            <h1 className="banner-post__title">{i18n.t(k.SEMISERVICE)}</h1>
            <div className="banner-post__text">
              <p className="banner-post__text">
                {i18n.t(k.KAITECHSEMI)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHSEMI1)}
              </p>
              <p className="banner-post__text">
                {i18n.t(k.KAITECHSEMI2)}
              </p>
            </div>
            <Link to="/contact" className="banner-post__cta_contact">
              {i18n.t(k.ENTRECONTATO)}
            </Link>
            <Link to="#top" className="banner-post__cta \
              fa fa-angle-up arrowrow arrow-small" onClick={this.topFunction}>
            </Link>
          </div>
        </div>
        <hr />
        <div className="container">
          <h1 className="center page-header">
            {i18n.t(k.KAITECH)}
          </h1>
          <hr />
          <img data-testid="html-element" className="img-center img-fluid"
          src= {help} alt="Contact Help" />
            <div className="page-text-end">
              <div className="page-help">
                <p className="page-help ">{i18n.t(k.KAIABOUTMSG4)}</p>
              </div>
              <div className="page-help">
                <Link to="/contact" className="page-help-border">
                  {i18n.t(k.ENTRECONTATO)}
                </Link>
              </div>
            </div>
        </div>
        <div style={divOpacity} className="load"></div>
    </div>
    <Footer />
    </>
    );
  }

}

export default Home;
