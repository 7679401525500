import React, { Component } from 'react';

import ReactGA from 'react-ga';

import i18n from 'i18next';
import k from "./../i18n/keys";

import axios from 'axios';

//import './../assets/Users.scss';
import './../assets/wim.scss';
//import './../assets/Users.scss';

import { UserContext } from '../App';
import Footer from './Footer';


class Newsletter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      username: UserContext._currentValue.user,
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      aux1: '',
      aux2: '',
      token: UserContext._currentValue.token,

      errors: [],
      error_show: 'none',
      loading: false,
      opacity: 0,

      perPage: 5,
      offset: 0,
      currentPage: 0,
      numPages: 0,
      col: '',
      order: '',
    }
    if (this.state.numPages === 0 ) {
      localStorage.setItem('col', 'id');
      localStorage.setItem('order', 'ASC');
      localStorage.setItem('offset', 0);
      localStorage.setItem('curPage', 0);
    }

  }

  handleSelection = (e) => {
      //this.props.history.push('/');
      this.props.history.push('/');
  }

  onChange = (e) => {
    if (e.target.value !== ''){
      this.setState({ [e.target.name]: e.target.value});
      //this.componentDidMount();
    }
  }

  handlePagination = (e) => {
    if (e.target.name !== '...') {
      let current = document.getElementsByClassName('pag-number');
      let count = 11;
      if (this.state.numPages < 11) {
        count = this.state.numPages;
      }
      for (let i = 0; i < count; i++) {
        current[i].classList.remove("active");
      }
      if (e.target.name === '-1') {
        if (localStorage.getItem('curPage')  > 0) {
          localStorage.setItem('curPage',
          localStorage.getItem('curPage')  -  1);
        }
      } else if ((e.target.name === '+1')){
        if (localStorage.getItem('curPage')  < this.state.numPages - 1) {
          localStorage.setItem('curPage',
          parseInt(localStorage.getItem('curPage'))  + 1);
        }
      } else {
        localStorage.setItem('curPage', e.target.name - 1);
        if (e.target.name > 5 && this.state.numPages > 10) {
          current[10 - (this.state.numPages -
            e.target.name)].classList.add('active');
        } else {
        current[e.target.name - 1].classList.add('active');
        }
      }
      localStorage.setItem('offset',
      localStorage.getItem('curPage') * this.state.perPage);
      this.setState({offset: localStorage.getItem('offset')});
      //this.componentDidMount(localStorage.getItem('offset'));
    }
  }

  async list_users() {
    if (this.state.username !== '' && this.state.username!== null) {

      let config_users = {
        headers: {
          'Authorization': "Bearer " + this.state.token,
          'Content-Type': 'application/json'
        }
      };
      if (localStorage.getItem('token') !== ''){
        this.setState({opacity: 1});
        let url = 'https://kaitech.herokuapp.com/newsletter_count';
        await axios.get(url, config_users)
        .then(response => {
          //this.setState({data: response.data});
          this.setState({numPages:
            Math.ceil(response.data / this.state.perPage)});
          sessionStorage.setItem('numPages',
            Math.ceil(response.data / this.state.perPage));
          this.setState({opacity: 0});
        })
        .catch((error) => {
          this.setState({errors: []});
          let array = [];
          if (error.request.status === 401){
            array.push(`${error.request.status} -
              ${i18n.t(k.NOTAUTHORIZEDPLEASELOGIN)}`);
          }
          this.setState({errors: array});
          this.setState({error_show: 'block'});
          this.setState({ loading: false });
          this.setState({opacity: 0});
        });

        url = 'https://kaitech.herokuapp.com/newsletter/list?';
        const limit = "limit=" + this.state.perPage;
        const offset = "&offset=" + localStorage.getItem('offset');
        const col = "&col=" + localStorage.getItem('col');
        const order = "&order=" + localStorage.getItem('order');
        const query = url + limit + offset + col + order;
        await axios.get(query, config_users)
        .then(response => {
          this.setState({data: response.data});
          this.setState({opacity: 0});
        })
        .catch((error) => {
          this.setState({errors: []});
          let array = [];
          if (error.request.status === 401){
            array.push(`${error.request.status} -
              ${i18n.t(k.NOTAUTHORIZEDPLEASELOGIN)}`);
          }
          this.setState({errors: array});
          this.setState({error_show: 'block'});
          this.setState({ loading: false });
          this.setState({opacity: 0});
        });
      } else {
        let array = [];
        array.push(`${i18n.t(k.NOTAUTHORIZEDPLEASELOGIN)}`);
        this.setState({error_show: 'block'});
        this.setState({errors: array});
      }
    }
  }

  componentDidMount() {
    this.list_users();
    document.getElementById('footer').classList.add("footer");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.perPage !== this.state.perPage || prevState.offset !== this.state.offset) {
      this.list_users();
    }
  }

  componentWillUnmount() {
    document.getElementById('footer').classList.remove('footer');

  }

  render() {
    ReactGA.event({
        category: "Lists",
        action: `Access to Subscribed newsletter from
          ${this.state.username}`,
        labels: 'Kaitech Newsletter'
    });
    const { errors, error_show} = this.state;
    const divOpacity = {
			opacity: this.state.opacity,
		};

    const divStyle = {
      display: error_show,
      backgroundColor: 'pink',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '30%'
    };

    const showPage = [];
    let dots = '';
    if (sessionStorage.getItem('numPages')) {
      for (let i= 1; i <= sessionStorage.getItem('numPages'); i++) {
        if (sessionStorage.getItem('numPages') < 10) {
          showPage.push(i);
        } else {
          if ( (i > parseInt(sessionStorage.getItem('numPages')) - 5)
          || (i <= 5) ) {
            showPage.push(i);
          } else {
            if (dots === '') {
              dots = '...';
              showPage.push(dots);
            }
          }
        }
      }
    }

    return(
      <>
      <div className="">
        <h1 className="center page-header">{i18n.t(k.KAITECH)} -
        {i18n.t(k.SUBSCRIBERS)}</h1>
        <hr />
      {(this.state.username !== '' && this.state.username!== null) ?
      <React.Fragment>
        <div className="row paginate">
          <button className="pag-arrows"
            onClick={this.handlePagination}
            name='-1'>{i18n.t(k.PREVIOUS)}
          </button>
            {showPage.map((page, key) =>
            <button key={ key }
              className="pag-number"
              onClick={this.handlePagination}
              name={page}>{page}
            </button>
            )}
          <button className="pag-arrows"
            onClick={this.handlePagination}
            name='+1'>{i18n.t(k.NEXT)}
          </button>

          <label className="user-label-new">
            {'Items:'}
            <select onChange={this.onChange}
              onClick={this.clearForm}
              name="perPage"
              className="form-control pag-label"
              placeholder={i18n.t(k.PERPAGE)}>
              <option></option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>25</option>
            </select>
          </label>

        </div>

        <div className="grid-news-header table-head-newsletter">
          <div className="grid-table-newsletter head1">{i18n.t(k.FIRSTNAME)}</div>
          <div className="grid-table-newsletter head2">{i18n.t(k.LASTNAME)}</div>
          <div className="grid-table-newsletter head3">{i18n.t(k.TITLE)}</div>
          <div className="grid-table-newsletter head4">{i18n.t(k.COMPANY)}</div>
          <div className="grid-table-newsletter head5">Email</div>
          <div className="grid-table-newsletter head6">{i18n.t(k.PHONE)}</div>
          <div className="grid-table-newsletter head7">{i18n.t(k.OPTION)}</div>
          <div className="grid-table-newsletter head8">{i18n.t(k.STATUS)}</div>
          <div className="grid-table-newsletter head9">{i18n.t(k.SOURCE)}</div>
        </div>
        <>
        <div className="grid-news-data table-newsletter">
          {this.state.data.map((dado, key) =>
            <React.Fragment key= {key}>
              <div className="table-newsletter field1">{dado.firstname}</div>
              <div className="table-newsletter field2">{dado.lastname}</div>
              <div className="table-newsletter field3">{dado.title}</div>
              <div className="table-newsletter field4">{dado.company}</div>
              <div className="table-newsletter field5">{dado.email}</div>
              <div className="table-newsletter field6">{dado.phone}</div>
              <div className="table-newsletter field7">{dado.option}</div>
              <div className="table-newsletter field8">{dado.status}</div>
              <div className="table-newsletter field9">{dado.source}</div>
            </React.Fragment>
            )}
        </div>
        </>

        <br />
        <button className="btn btn-secondary btn-lg pag-list"
          onClick={this.handleSelection}
          name='cancel'>{i18n.t(k.BACK)}
        </button>


        </React.Fragment>
        :
        <>
        <div>
          <br />
          <h1 className="home-text center">{i18n.t(k.NOTLOGGED)}</h1>
        </div>

        </>
        }

        <div style={divOpacity} className="load"></div>
        <div className="errors" style={divStyle}>
          <ul>{i18n.t(k.ERROR)}: {errors.map((error,key) =>
            <li key={key}>{error}</li>)}</ul>
        </div>
      </div>
      <Footer />
      </>
    )
  }
}

export default Newsletter;
