import React, { Component } from 'react';

import ReactGA from 'react-ga';

import map from '../assets/images/map_pins.png';
import graph from '../assets/images/graph_d3.png';
import './../assets/wim.scss';
import './../assets/dashboard1.scss';

import { UserContext } from '../App';

//import * as d3 from 'd3';

import Footer from './Footer';

class Dashboard1 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      interval: '',
      username: UserContext._currentValue.user,
      value: 0,

    }

  }



  componentDidUpdate(prevProps, prevStates) {

  };


  render() {

    ReactGA.event({
        category: "Dashboard",
        action: `Access to Dashboard from
          ${this.state.username}`,
        labels: 'Kaitech Dashboard'
    });

    return(
      <React.Fragment>
      <div className="container-dash" id="top">
        <h2>Kaitech Consulting Support Services around the world</h2>
        <hr />
        <div className="graph-area1-container">
          <div className="graph1">

            <div className="graph-box">
              <div className="graph-box-texts">
                <div className="graph-box-texts-percentage">
                  <p className="graph-box-texts-percentage-size"
                  style={{color: 'cyan'}}
                  >32%</p>
                </div>
                <div className="graph-box-texts-text">
                  <p className="graph-box-texts-text-size">
                  Customers supported in IoT Technologies.</p>
                </div>
              </div>

              <div className="graph-box-bar">
                <div className="container-bar">
                  <div className="bar-format bar-type"
                  style={{backgroundColor: 'cyan', width: '32%'}}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="graph1">

            <div className="graph-box">
              <div className="graph-box-texts">
                <div className="graph-box-texts-percentage">
                  <p className="graph-box-texts-percentage-size"
                  style={{color: 'steelblue'}}
                  >45%</p>
                </div>
                <div className="graph-box-texts-text">
                <p className="graph-box-texts-text-size">
                Customers supported in Automotive Technologies.</p>
                </div>
              </div>
              <div className="container-bar">
                <div className="graph-box-bar">
                  <div className="bar-format bar-type"
                    style={{backgroundColor: 'steelblue', width: '45%'}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="graph1">
            <div className="graph-box">
              <div className="graph-box-texts">
                <div className="graph-box-texts-percentage">
                  <p className="graph-box-texts-percentage-size"
                  style={{color: 'blue'}}
                  >23%</p>
                </div>
                <div className="graph-box-texts-text">
                <p className="graph-box-texts-text-size">
                Customers supported in Communications Technologies..</p>
                </div>
              </div>
              <div className="container-bar">
                <div className="graph-box-bar">
                  <div className="bar-format bar-type"
                  style={{backgroundColor: 'blue', width: '23%'}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="graph-area2-container">

          <div className="graph2">
            <div className="graph-box2">
              <p className="graph-box2-title">
                Graph 1 Title
              </p>
              <img className="graph-box2-graph" src={graph} alt="Graph"/>
            </div>
            <div className="graph-box2">
              <p className="graph-box2-title">
                Graph 2 Title
              </p>
              <img className="graph-box2-graph" src={graph} alt="Graph"/>
            </div>
            <div className="graph-box2">
              <p className="graph-box2-title">
                Graph 3 Title
              </p>
              <img className="graph-box2-graph" src={graph} alt="Graph"/>
            </div>
            <div className="graph-box2">
              <p className="graph-box2-title">
                Graph 4 Title
              </p>
              <img className="graph-box2-graph" src={graph} alt="Graph"/>
            </div>
          </div>

          <div className="map">
            <img src={map} alt="Graph"/>
          </div>

        </div>

      </div>

      <Footer />
      </React.Fragment>


    )
  }
}


export default Dashboard1;
