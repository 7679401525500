import React from "react";

const Component404 = props => {
  return (
      <div>
        <hr />
        <h1 className="home-text">
          '404 - Page Not Found'
        </h1>
      </div>
  );
};

export default Component404;
