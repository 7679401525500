import React, { Component } from 'react';

import './../assets/kaitech.scss';

export class Billboard extends Component {
  constructor(props){
    super(props)

    //var span = document.getElementsByClassName("close")[0];
    //var modal = document.getElementById("myModal");

    this.spanOnClick = this.spanOnClick.bind(this);
  }
  componentDidMount() {
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    if (this.props.image === 'image1'){
      modal.classList.remove('modal-background-error');
      modal.classList.add('modal-background');
    } else {
      modal.classList.remove('modal-background');
      modal.classList.add('modal-background-error');
    }
	}


spanOnClick() {
  var modal = document.getElementById("myModal");
  modal.style.display = "none";
  this.props.history.push('/home');
}

  render() {

    return(

        <section id="myModal" className="modal-background modal">
          <i className="close fa fa-times" onClick={this.spanOnClick}></i>
          <div className="modal-headline">
            <div>
              <h2 className="modal-text modal-content">{this.props.text}</h2>
            </div>
            <div className="login">
              <a
                className="modal-url modal-content-url"
                href="https://www.kaiPixel.com"
                // eslint-disable-next-line
                target="_blank"
                rel="noopener noreferrer">{this.props.message}
              </a>
            </div>
          </div>
        </section>

    )
  }
}

export default Billboard;
