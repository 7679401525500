import React, { Component } from 'react';

import i18n from 'i18next';
import k from "./../i18n/keys";

import ReactGA from 'react-ga';

import { UserContext } from '../App';

//import axios from 'axios';
import './../assets/kaitech.scss';
import './../assets/wim.scss';
import emailjs from 'emailjs-com';

import Footer from './Footer';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: UserContext._currentValue.user,
      firstname: '',
      lastname: '',
      title: '',
      company: '',
      email: '',
      feedback: '',
      phone: '',
      option: 0,
      newsletter: false,
      textarea: '',
      optionList: [
        '',
        i18n.t(k.MKTDIGITAL),
        i18n.t(k.IOTSERVICE),
        i18n.t(k.WIMSERVICE),
        i18n.t(k.AUTOSERVICE),
        i18n.t(k.WEBSERVICE),
        i18n.t(k.SEMISERVICE),
      ],
      optionValue: '',

      errors: [],
      error_show: 'none',
      // loading: false,
      // isLoading: false,
      opacity: 0,
      ok: false
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    //this.snackBar = this.snackBar.bind(this);
  }


  validateForm = () => {
    this.setState({errors: []});
    let array = [];
    if (this.state.firstname=== ''){
      array.push(` ${i18n.t(k.FIRSTNAME)}.\r\n`);
    };
    if (this.state.lastname === ''){
      array.push(` ${i18n.t(k.LASTNAME)}.\r\n`);
    };
    if (this.state.company === ''){
      array.push(` ${i18n.t(k.COMPANY)}.\r\n`);
    };
    if (this.state.email === ''){
      array.push(" Email.\r\n");
    };
    if (this.state.phone === ''){
      array.push(` ${i18n.t(k.PHONE)}.\r\n`);
    };
    if (array.length === 0) {
      return true
    }
    this.setState({errors: array});
    this.setState({error_show: 'block'});
    return false;
  }

  clearForm = (e) => {
    this.setState({errors: []});
    this.setState({error_show: 'none'});
  }

  snackBar(message) {
    var x = document.getElementById("snackbar");
    x.innerHTML = message;
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  onChange(e) {
    this.setState({errors: []});
    //e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: e.target.value});
  }

  componentDidMount() {
  	this.setState({opacity: 0})
    document.getElementById('firstname').focus();
    (function(){
       emailjs.init("user_7kPkOhEo0c6N7DHtmJ9o7");
    })();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
	}

  componentDidUpdate(prevProps, prevStates) {
  }

  async onSubmit(e) {
    e.preventDefault();
    if (!this.validateForm()) {
      return
    }

//emailjs.send("gmail", "kaitech", {"reply_to":"ascalmon@gmail.com","to_name":"Antonio","from_name":"ascalmon@kaitech.com.br","message_html":"Hello World"})
    const templateParams = {
      //reply_to: "administracao@kaitech.com.br",
      reply_to: "ascalmon@gmail.com",
      from_name:
        this.state.firstname + " " +
        this.state.lastname + " (" +
        this.state.email + ")",
      to_name: "Kaitech Consulting - SAC",
      feedback: this.state.feedback,
      message_html:
      `Name: ${this.state.firstname} ${this.state.lastname}
      <br> Title: ${this.state.title}
      <br> Company: ${this.state.company}
      <br> Phone: ${this.state.phone}
      <br> Segment: ${this.state.optionValue}
      <br> Message: ${this.state.textarea}
      <br>
      <br>Newsletter: ${this.state.newsletter ? 'Yes' : 'No'}`
    };

    emailjs
      .send("gmail", "kaitech", templateParams)
      .then(
        function(response) {
          var x = document.getElementById("snackbar");
          x.innerHTML = "Your message was sent successfully";
          x.className = "show";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
        },
        function(err) {
          var x = document.getElementById("snackbar");
          x.innerHTML = "Your message was not able to be sent";
          x.className = "show";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          //this.snackBar("Your message was not able to be sent");
          console.log(err);
        }

      );

      this.setState({
            firstname: '',
            lastname: '',
            title: '',
            company: '',
            phone: '',
            email: "",
            feedback: "",
            newslette: '',
            textarea: '',
            optionValue: '',
          });

    // let last_login = Date.now()
    // last_login = new Date(last_login);

    // var config_signup = {
    //     headers: {
    //       'Content-Type': 'application/json'
    //             }
    // };

    // var bodyParameters_signup = {
    //   user: {
    //     username: this.state.username,
    //     email: this.state.email,
    //     password: this.state.password,
    //     logged: false,
    //     obs: i18n.t(k.MSGSIGNUP),
    //     role: admin,
    //     last_login: last_login
    //   }
    // };

   // if (this.validate_form()) {
   //   this.setState({opacity: 1});
   //   const url = 'https://react-api-wim.herokuapp.com/users';
   //   await axios.post(url, bodyParameters_signup, config_signup)
   //   .then(res => {
   //     this.setState({opacity: 0});
   //     alert('User created!');
   //     this.props.history.push('/home');
   //    })
   //    .catch((error)=>{
   //
   //    });
   //  }
  }

  render() {
    ReactGA.event({
        category: "Contact",
        action: `Access to Contact Form from
          ${this.state.username}`,
        labels: 'Kaitech Contact'
    });

    const {errors, error_show, isLoading} = this.state;
    const divOpacity = {
			opacity: this.state.opacity,
		};
    const divStyle = {
      display: error_show,
      backgroundColor: '#c5d6e6',
    };

    const title = i18n.t(k.CONTACTUS);

    const optionList = this.state.optionList;

    return (
      <>
      <section className="sec1 container col-lg-4 col-sm-6">
        <h2 className="signup-text">{i18n.t(k.CONTACTUS)}</h2>
        <hr></hr>
        <div className="signup error_show">
          <form onSubmit={this.onSubmit}>
            <div className="row signup-data">
              <div className="">
                <input
                  value ={this.state.firstname}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="form-control"
                  placeholder={i18n.t(k.FIRSTNAME) + ' *'}
                  autoComplete="on"
                />
              </div>
              <div className="">
                <input
                  value ={this.state.lastname}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="form-control"
                  placeholder={i18n.t(k.LASTNAME) + ' *'}
                  autoComplete="on"
                />
              </div>
              <div className="">
                <input
                  value ={this.state.title}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="title"
                  id="title"
                  className="form-control"
                  placeholder={i18n.t(k.TITLE)}
                  autoComplete="on"
                />
              </div>
              <div className="">
                <input
                  value ={this.state.company}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="company"
                  id="company"
                  className="form-control"
                  placeholder={i18n.t(k.COMPANY) + ' *'}
                  autoComplete="on"
                />
              </div>
              <div className="">
                <input
                  value ={this.state.email}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email *"
                  autoComplete="on"
                />
              </div>
              <div className="">
                <input
                  value ={this.state.phone}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="text"
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder={i18n.t(k.PHONE) + ' *'}
                  autoComplete="on"
                />
              </div>
              <div className="form-select">
                <label htmlFor="option" className="form-select-label">
                  {i18n.t(k.OPTIONS)}
                </label>
                  <select
                    onChange={this.onChange}
                    onClick={this.clearForm}
                    name="optionValue"
                    id="optionValue"
                    className="form-control"
                    value={this.state.optionValue}
                  >
                  {optionList.map((item, id) => {
                    return (
                    <option key={id}
                      className="form-select-option"
                      value={item}>{item}
                    </option>
                  )})}
                  </select>
              </div>

              <hr />
              <div className="">
                <textarea
                  value ={this.state.textarea}
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  rows="2"
                  cols="50"
                  name="textarea"
                  id="textarea"
                  className="form-control"
                  placeholder={i18n.t(k.TEXTAREA)}
                  autoComplete="on"
                />
              </div>
              <br />
              <div className="form-group">
                <input
                  onChange={this.onChange}
                  onClick={this.clearForm}
                  type="checkbox"
                  name="newsletter"
                />
                <label className="newsletter">{i18n.t(k.NEWSLETTER)}</label>
              </div>
            </div>
          </form>
          <form method="POST">
            <div
              className="g-recaptcha recaptcha-color"
              data-sitekey="6LcOPsgZAAAAAGR2zYx8NJ08WvtfQ3DZZH_AK_Gr"
            >
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-lg"
                disabled={isLoading}
                type='submit'
                name='submit'
                onClick={this.onSubmit}>
                {title}
              </button>
            </div>
          </form>
          <div id="snackbar">Some text some message..</div>
          <div className="errors" style={divStyle}>
            <ul>{i18n.t(k.MISSING)}: {errors.map((error,key) =>
              <li key={key}>{error}</li>)}</ul>
          </div>
        </div>
        <div style={divOpacity} className="load"></div>

      </section>
      <Footer />
      </>
    )
  }
}

export default Contact;
