import i18next from 'i18next';

//const english = require('./english');
import { english } from './english';
//const portuguese = require('./portuguese');
import { portuguese } from './portuguese';

i18next.init({
  lng: sessionStorage.getItem('lng') || 'pt-BR',
  debug: true,
  resources: {
    en: { translation: english },
    'pt-BR': {translation: portuguese},
  },
});



// Add this line to your app entrypoint. Usually it is src/index.js
// import './i18n/init';
